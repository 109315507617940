import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'StringToMoneyFilter' })
export class StringToMoneyFilter implements PipeTransform {
  public transform(text: string | number): string {
    if (typeof(text) === 'number') {
      text = JSON.stringify(text);
    }

    return new DecimalPipe('en-UK').transform(parseFloat(text), '1.2-2');
  }
}
