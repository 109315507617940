import { Injectable } from '@angular/core';
import { ScreenSizeState } from '@app/models/shared/screen-size-state';
import { BehaviorSubject, Observable, debounceTime, fromEvent, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  public screenSizeState$: Observable<ScreenSizeState>;

  private readonly _mobileDeviceInnerWidth: number = 479;
  private readonly _shortDeviceHeight: number = 701;
  private readonly _tabletDeviceInnerWidth: number = 1023;

  private _screenSizeStateSource = new BehaviorSubject<ScreenSizeState>(this.getScreenSizeState());

  constructor() {
    this.init();
    this.screenSizeState$ = this._screenSizeStateSource.asObservable();
  }

  private init(): void {
    fromEvent(window, 'resize')
        .pipe(
            debounceTime(100), // Debounce to avoid excessive updates
            map(() => this.getScreenSizeState())
        )
        .subscribe((state: ScreenSizeState) => this._screenSizeStateSource.next(state));
  }

  private getScreenSizeState(): ScreenSizeState {
    return {
      isLandscape: window.matchMedia('(orientation: landscape)').matches,
      isMobile: window.innerWidth <= this._mobileDeviceInnerWidth,
      isPortrait: window.matchMedia('(orientation: portrait)').matches,
      isShortDevice: window.innerWidth < this._shortDeviceHeight,
      isTablet: window.innerWidth <= this._tabletDeviceInnerWidth
    };
  }
}
