import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { fromEvent, throttleTime, debounceTime } from 'rxjs';

@Directive({
  selector: '[appViewHeight]',
  standalone: true,
})
export class ViewHeightDirective implements AfterViewInit {
  @Input() private offset: number;
  @Input() private viewHeightDisabled: boolean;

  private domElement: HTMLElement;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.domElement = this.elementRef.nativeElement as HTMLElement;

    // register on window resize event
    fromEvent(window, 'resize')
        .pipe(throttleTime(500), debounceTime(500))
        .subscribe(() => this.setHeight());
  }

  ngAfterViewInit() {
    this.setHeight();
  }

  private setHeight(): void {
    if (!this.viewHeightDisabled) {
      const height = window?.innerHeight - this.offset;
      this.renderer.setStyle(this.domElement, 'max-height', `${height}px`);
    }
  }
}
