import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateTimeFormatFilter',
  pure: false
})
export class DateTimeFormatFilter implements PipeTransform {
  public transform(date: string | Date, format: string): string | moment.Moment {
    if (!(date instanceof Date)) {
      date = date?.toUpperCase();
    }

    const response: string | moment.Moment = format ? moment(date).format(format) : moment(date);

    if (!moment(date, moment.ISO_8601).isValid()) {
      console.log('invalid date: ', date);
    }

    return response;
  }
}
