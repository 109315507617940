import { Injectable, OnDestroy } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { Tenant } from '@app/models/tenant';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { IDictionary } from '@app/models/IDictionary';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { INotification } from '@app/models/INotification';

@Injectable()
export class ToastsService implements OnDestroy {
  private tenant: Tenant;
  private subscription$: Subscription;
  public activeNotifications$: Observable<IDictionary<Observable<INotification>>>;

  private _activeNotifications: BehaviorSubject<IDictionary<Observable<INotification>>>;

  constructor(
    private toastr: ToastrService,
    private configurationService: ConfigurationService
  ) {
    this.subscription$ = new Subscription();
    this.subscription$.add(
        this.configurationService.isTenantReady.subscribe((status: boolean) => {
          if (status) {
            this.tenant = this.configurationService.tenant;
          }
        })
    );

    this._activeNotifications = new BehaviorSubject<IDictionary<Observable<INotification>>>({});
    this.activeNotifications$ = this._activeNotifications.asObservable();
  }

  ngOnDestroy() {
    this.subscription$?.unsubscribe();
  }

  public showToast(type: ToastTypes, message: string, title?: string, duration: number = 5000): void {
    const options: Partial<IndividualConfig> = {
      positionClass: 'toast-top-left',
      timeOut: duration
    };

    switch (type) {
      case ToastTypes.success: {
        this.toastr.success(message, this.tenant.CustomerYayText, options);
        break;
      }
      case ToastTypes.error: {
        this.toastr.error(message, title, options);
        break;
      }
      case ToastTypes.warning: {
        this.toastr.warning(message, title, options);
        break;
      }
      default: {
        this.toastr.info(message, title, options);
        break;
      }
    }
  }

  public emitNotification(id: string, type: ToastTypes, message: string, title?: string, duration: number = 3000): void {
    const value: IDictionary<Observable<INotification>> = this._activeNotifications.value;

    value[id] = new Observable((observer: Subscriber<INotification>) => {
      observer.next({ duration, message, title, type });
      setTimeout(() => {
        observer.next(null);
        value[id] = null;
      }, duration);
    });

    this._activeNotifications.next(value);
  }
}
