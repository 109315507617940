import { BasketTelemetry } from '@app/models/app-initialisers/BasketTelemetry';

export class BasketErrorTelemetry extends BasketTelemetry {
  constructor(
    readonly basketId: string,
    readonly action: string,
    readonly errorMessage: string,
    readonly errorCode: string,
  ) {
    super(basketId, action);
    this.properties['Error Message'] = errorMessage;
    this.properties['Error Code'] = errorCode;
    this.properties['Error Message'] = errorMessage;
  }
}
