import { OnDestroy, isDevMode, Injectable } from '@angular/core';

import { AppInjector } from '@app/core/injector.core';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { InsightsService } from '@app/app-initialisers/insights-service/insights.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Subject, Subscription } from 'rxjs';
import { DateTimeSplit } from '@app/models/date-time-split.enum';

/**
 * Parent class that handles services and components functionality
 * @export
 * @abstract
 * @class AndroWebCoreBase
 * @implements {OnDestroy}
 */
@Injectable()
export abstract class AndroWebCoreBase implements OnDestroy {
  protected subscriptions$: { [key: string]: Subscription } = {};
  protected destroy$ = new Subject<void>();

  protected readonly _insightsService: InsightsService;

  protected constructor() {
    const injector = AppInjector.getInjector();
    this._insightsService = injector.get(InsightsService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    for (const key in this.subscriptions$) {
      if (this.subscriptions$[key]) {
        this.subscriptions$[key].unsubscribe();
      }
    }
  }

  /**
   * Returns the given date as a string value.
   * @param value
   * @memberof AndroWebCoreBase
   * @public
   */
  public getStringFromDate(value: Date, split?: DateTimeSplit): string {
    const _value: Date = new Date(value);
    const year: string = this.addLeadingZero(_value.getFullYear());
    const month: string = this.addLeadingZero(_value.getMonth() + 1);
    const date: string = this.addLeadingZero(_value.getDate());
    const hours: string = this.addLeadingZero(_value.getHours());
    const minutes: string = this.addLeadingZero(_value.getMinutes());
    const seconds: string = this.addLeadingZero(_value.getSeconds());

    switch (split) {
      case DateTimeSplit.tradingDateStart: {
        return this.getTradingDateStart(year, month, date, hours, minutes, _value);
      }
      case DateTimeSplit.tradingDateEnd: {
        return this.getTradingDateEnd(year, month, date, hours, minutes, _value);
      }
      case DateTimeSplit.tradingDate: {
        return this.getTradingDate(year, month, date, hours, minutes, _value);
      }
      case DateTimeSplit.date: {
        return `${year}-${month}-${date}`;
      }
      case DateTimeSplit.time: {
        return `${hours}:${minutes}`;
      }
      default: {
        return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.000`;
      }
    }
  }

  /**
   * returns the start of the trading date.
   * @param year
   * @param month
   * @param day
   * @param hours
   * @param minutes
   * @param value
   */
  private getTradingDateStart(year: string, month: string, day: string, hours: string, minutes: string, value: Date): string {
    let dateHalf: string = `${year}-${month}-${day}`;

    if (+hours < 7 || (+hours === 6 && +minutes < 30)) {
      value.setDate(value.getDate() - 1);
      const _year: string = this.addLeadingZero(value.getFullYear());
      const _month: string = this.addLeadingZero(value.getMonth() + 1);
      const _date: string = this.addLeadingZero(value.getDate());
      dateHalf = `${_year}-${_month}-${_date}`;
    }

    return `${dateHalf}T06:30:00.000`;
  }

  /**
   * returns the end of the trading date.
   * @param year
   * @param month
   * @param day
   * @param hours
   * @param minutes
   * @param value
   */
  private getTradingDateEnd(year: string, month: string, day: string, hours: string, minutes: string, value: Date): string {
    let dateHalf: string = `${year}-${month}-${day}`;

    if (+hours >= 7 || (+hours === 6 && +minutes > 30)) {
      value.setDate(value.getDate() + 1);
      const _year: string = this.addLeadingZero(value.getFullYear());
      const _month: string = this.addLeadingZero(value.getMonth() + 1);
      const _date: string = this.addLeadingZero(value.getDate());
      dateHalf = `${_year}-${_month}-${_date}`;
    }

    return `${dateHalf}T06:29:59.999`;
  }

  /**
   * returns the trading date.
   */
  private getTradingDate(year: string, month: string, day: string, hours: string, minutes: string, value: Date): string {
    if (+hours >= 7 || (+hours === 6 && +minutes >= 30)) {
      return `${year}-${month}-${day}`;
    } else {
      const _date: Date = new Date(value);
      _date.setDate(value.getDate() - 1);
      const _year: string = this.addLeadingZero(_date.getFullYear());
      const _month: string = this.addLeadingZero(_date.getMonth() + 1);
      const _day: string = this.addLeadingZero(_date.getDate());
      return `${_year}-${_month}-${_day}`;
    }
  }

  /**
   * Logs an exception
   * @param exception
   * @param isFatal
   */
  public trackException(exception: any, isFatal: boolean): void {
    if (isDevMode()) {
      console.log(exception);
    }
    this._insightsService.trackException(exception, isFatal);
  }

  /**
   * Logs an trace
   * @param exception
   * @param isFatal
   */
  public trackTrace(message: string, severity: SeverityLevel, properties?: ICustomProperties): void {
    if (isDevMode()) {
      console.log(message);
    }
    this._insightsService.trackTrace(message, severity, properties);
  }

  /**
   * Adds a leading zero to all single digit numbers.
   * @memberof AndroWebCoreBase
   * @protected
   */
  protected addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  protected sequenceEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
      return false;
    }

    return array1.every((value, index) => value === array2[index]);
  }
}
