import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { InsightsService } from '@app/app-initialisers/insights-service/insights.service';
import { Observable, lastValueFrom } from 'rxjs';
import { ApiService } from './api.service';
import { AppInjector } from '@app/core/injector.core';

/**
 * Base class for service calls
 */
export abstract class ApiExtendedService extends ApiService {
  protected insightsService: InsightsService;
  protected configurationService: ConfigurationService;

  constructor() {
    super();
    const injector = AppInjector.getInjector();
    this.insightsService = injector.get(InsightsService);
    this.configurationService = injector.get(ConfigurationService);
  }

  /**
   * Calls the `get` with the given endpoint. If an error is returned it is tracked to insights service and returned.
   */
  protected getResource<T>(endpoint: string, params?: HttpParams, headers?: { [id: string]: string; }): Observable<T | HttpErrorResponse> {
    return this.get<T>('body', `${this.configurationService.getApiBaseUrlForTenant()}/${endpoint}`, params, headers) as Observable<T | HttpErrorResponse>;
  }

  /**
   * Calls the `get` with the given endpoint. If an error is returned it is tracked to insights service and returned.
   * @param endpoint
   * @param params
   * @param headers
   */
  protected getResourceAsync<T>(endpoint: string, params?: HttpParams, headers?: { [id: string]: string; }): Promise<T | HttpErrorResponse> {
    try {
      return lastValueFrom(this.getResource<T>(endpoint, params, headers));
    } catch (e) {
      return this.catchError(e);
    }
  }

  /**
   * Calls the `post` with the given endpoint and body. If an error is returned it is tracked to insights service and returned.
   */
  protected async postResource<T, D>(endpoint: string, body?: D, params?: HttpParams): Promise<HttpResponse<T> | HttpErrorResponse> {
    try {
      return await lastValueFrom(this.postWithFullResponse<T, D>(`${this.configurationService.getApiBaseUrlForTenant()}/${endpoint}`, body, params));
    } catch (e) {
      return this.catchError(e);
    }
  }

  /**
   * Calls the `put` with the given endpoint and body. If an error is returned it is tracked to insights service and returned.
   */
  protected putResource<T, D>(endpoint: string, body?: D, params?: HttpParams): Observable<T | HttpErrorResponse> {
    return this.put<T, D>('body', `${this.configurationService.getApiBaseUrlForTenant()}/${endpoint}`, body, params) as Observable<T | HttpErrorResponse>;
  }

  /**
   * Calls the `delete` with the given endpoint. If an error is returned it is tracked to insights service and returned.
   */
  protected async deleteResource<T>(endpoint: string, params?: HttpParams): Promise<HttpResponse<T> | HttpErrorResponse> {
    try {
      return await lastValueFrom(this.deleteWithFullResponse<T>(`${this.configurationService.getApiBaseUrlForTenant()}/${endpoint}`, params));
    } catch (e) {
      return this.catchError(e);
    }
  }

  /**
   * tracks the given error and returns it in a resolved promise.
   * @param error
   */
  protected catchError(error: HttpErrorResponse): Promise<HttpErrorResponse> {
    this.insightsService.trackException(error, false);
    return Promise.resolve(error);
  }
}
