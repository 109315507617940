import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { HttpStatusCodeHandler } from '@app/core/http.status.codes';
import { Basket } from '@app/models/basket';
import { BasketDeal } from '@app/models/basket/basket-deal';
import { User } from '@app/models/user';
import { BasketService } from '@app/api/basket.service';
import { MenuService } from '@app/api/menu.service';
import { Deal } from '@app/models/menu/deal';

@Component({
  selector: 'app-basket-voucher',
  styleUrls: ['./basket-voucher.component.css', './../../shared-basket-styles.scss'],
  templateUrl: './basket-voucher.component.html'
})
export class BasketVoucherComponent extends AndroWebCoreComponent implements OnInit {
  @Input() public basket: Basket;
  @Input() public orderComplete: boolean;
  @Input() public disableVoucherEditing: boolean;

  @ViewChild('DealsModal') private _dealModal: TemplateRef<any>;
  @Input('user') private _user: User;

  public errorMessage: string;
  public isVoucherSaving: boolean;
  public inputFormControl: FormControl<string>;
  public deal: Deal;
  public unlockedDealNotApplied: boolean;

  private _dealsDialogRef: MatDialogRef<any, any>;

  constructor(
    private _menuService: MenuService,
    private _basketService: BasketService
  ) {
    super();
  }

  ngOnInit() {
    this.errorMessage = null;
    this.inputFormControl = new FormControl('', [Validators.required]);

    this.unlockedDealNotApplied = this.basket?.UnlockedProductIds?.length
      ? !!this.basket.Deals?.length || !this.basket.Deals.some((x: BasketDeal) => this.basket.UnlockedProductIds.includes(x.DealId))
      : false;
  }

  /**
   * applies a voucher to the basket.
   * @param voucher - the voucher code
   */
  public async applyVoucher(): Promise<void> {
    if (this.inputFormControl.invalid) {
      return;
    }

    if (this.inputFormControl.value.toUpperCase().startsWith('APP')) {
      this.errorMessage = 'This voucher is only available on our mobile app. Please download our app to redeem';
      return;
    }

    if (this.unlockedDealNotApplied) {
      await this.removeVoucher();
    }

    this.isVoucherSaving = true;
    this.inputFormControl.disable();
    this.errorMessage = null;

    if (this._user && this.basket && !this.basket.HasCustomer) {
      await this._basketService.setCustomerOnBasket(this.basket.Id, this._user.Id);
    }

    const response: HttpErrorResponse | HttpResponse<Basket> = await this._basketService.addVoucherToBasket(this.basket.Id, { voucherCode: this.inputFormControl.value });

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this.basket = response['body'];

      if (this.basket.UnlockedProductIds?.length > 0) {
        this.openDealModal();
      }
    } else {
      this.errorMessage = await this.getErrorMessage(response['error'].type);

      if (this.errorMessage === 'something has gone wrong' && response['error'].detail) {
        this.errorMessage = response['error'].detail;
      }
    }

    this.isVoucherSaving = false;
    this.inputFormControl.enable();
  }

  /**
   * Closes the deals modal.
   */
  public closeDealModal(): void {
    this._dealsDialogRef.close();
  }

  /**
   * Opens the deals modal.
   */
  private openDealModal(): void {
    this.deal = this._menuService.menuValue.Deals.find((x) => x.Id === this.basket.UnlockedProductIds[0]);

    this._dealsDialogRef = this.openDialog(this._dealModal, 'deals-component', this.getDealModalConfig());

    this._dealsDialogRef.afterClosed().subscribe((value: string) => {
      if (value === 'cancel') {
        this.removeVoucher();
      }
    });
  }

  /**
   * Sets up the voucher form.
   */
  private getDealModalConfig(): MatDialogConfig<void> {
    return {
      height: this.isMobile ? '100%' : '',
      maxHeight: '100%',
      panelClass: ['custom-dialog-two-container', 'product-modal'],
      width: this.isMobile ? '100%' : '450px'
    };
  }

  /**
  * removes a voucher from the basket
  */
  private async removeVoucher(): Promise<void> {
    const basket: Basket | null = await this._basketService.deleteVoucherFromBasket(this.basket.Id);

    if (basket) {
      this.basket = basket;
    }
  }
}
