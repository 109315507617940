/**
 * enumerator of the desired return type when converting a date to string
 */
export enum DateTimeSplit {
    time = 'time',
    date = 'date',
    tradingDate = 'tradingDate',
    tradingDateStart = "tradingDateStart",
    tradingDateEnd = "tradingDateEnd"
}
