import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from
  '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from '@app/api/user.service';

@Injectable()
export class Response401Interceptor implements HttpInterceptor {
  constructor(
    private userService: UserService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 401) {
              this.userService.login();
            }
          }

          return event;
        }),
        catchError((event: HttpEvent<any>) => {
          if (event instanceof HttpErrorResponse) {
            if (event.status === 401) {
              this.userService.logOutUser(true);
              this.userService.login();
            } else if (event.status === 403) {
              this.userService.logOutUser(true);
            }
          }

          return throwError(event);
        }));
  }
}
