import { Injectable, Inject, InjectionToken } from '@angular/core';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';
import { IAnalyticsProvider } from '@app/models/app-initialisers/IAnalyticsProvider';
import { ModifierSection } from '@app/models/modifier-section';

export const AnalyticsProvider = new InjectionToken<IAnalyticsProvider>('AnalyticsProvider');

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements IAnalyticsProvider {
  private providers: IAnalyticsProvider[];

  constructor(@Inject(AnalyticsProvider) analyticsProviders: IAnalyticsProvider[]) {
    this.providers = analyticsProviders;
  }
  pageView(path: string, title: string): void {
    this.providers.forEach((p) => {
      p.pageView(path, title);
    });
  }
  setupUserId(userID: string) : void {
    this.providers.forEach((p) => {
      p.setupUserId(userID);
    });
  }
  trackException(exception: Error, isFatal: boolean): void {
    this.providers.forEach((p) => {
      p.trackException(exception, isFatal);
    });
  }
  trackPerformance(category: string, path: string, time: number, label?: string): void {
    this.providers.forEach((p) => {
      p.trackPerformance(category, path, time, label);
    });
  }

  trackViewBasket(value: number, items: GoogleAnalyticsItem[]): void {
    this.providers.forEach((p: IAnalyticsProvider) => {
      p.trackViewBasket(value, items);
    });
  }

  trackViewProduct(productName: string, productId: string, category: string, price: number, currency: string, variant: string, coupon: string): void {
    this.providers.forEach((p) => {
      p.trackViewProduct(productName, productId, category, price, currency, variant, coupon);
    });
  }

  trackViewDeals(dealId: string, dealName: string, items: [GoogleAnalyticsItem]): void {
    this.providers.forEach((p: IAnalyticsProvider) => p.trackViewDeals(dealId, dealName, items));
  }

  trackClickProduct(productName: string, productId: string, category: string, price: number, currency: string): void {
    this.providers.forEach((p) => {
      p.trackClickProduct(productName, productId, category, price, currency);
    });
  }

  trackAddToBasket(value: number, items: GoogleAnalyticsItem[]): void {
    this.providers.forEach((p: IAnalyticsProvider) => {
      p.trackAddToBasket(value, items);
    });
  }

  trackRemoveFromBasket(value: number, items: GoogleAnalyticsItem[]): void {
    this.providers.forEach((p) => {
      p.trackRemoveFromBasket(value, items);
    });
  }

  trackBeginCheckout(value: number, coupon: string, items: GoogleAnalyticsItem[]): void {
    this.providers.forEach((p) => {
      p.trackBeginCheckout(value, coupon, items);
    });
  }

  trackCheckoutModifier(modifiers: ModifierSection[]): void {
    this.providers.forEach((p: IAnalyticsProvider) => {
      p.trackCheckoutModifier(modifiers);
    });
  }

  trackPurchaseAndClose(amount: number, orderId: string, voucherCode: string, items: GoogleAnalyticsItem[]): void {
    this.providers.forEach((p) => {
      p.trackPurchaseAndClose(amount, orderId, voucherCode, items);
    });
  }
}
