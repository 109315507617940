import { InsightsService } from '@app/app-initialisers/insights-service/insights.service';
import { ErrorHandler, Injectable } from '@angular/core';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';

declare const logToFullStory: Function;

@Injectable()
export class AndrowebErrorHandler implements ErrorHandler {
  constructor(private insightsService: InsightsService, private configurationService: ConfigurationService) {
  }

  /**
   * handles all uncaught errors across the app
   * @param error
   */
  public handleError(error: any): void {
    this.insightsService.trackException(error, false);

    const chunkFailedMessage: RegExp = /Loading chunk .+ failed/;

    if (error && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    if (this.configurationService.getEnvironment() === 'Development' || this.configurationService.getEnvironment() === 'Test') {
      console.error(error);
    } else {
      try {
        logToFullStory(error);
      } catch (e) {
        console.error(error);
      }
    }
  }
}
