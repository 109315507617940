import { Injector, NgModule } from '@angular/core';
import { AppInjector } from '@app/core/injector.core';

@NgModule({
})
export class AppInjectorModule {
  constructor(
    injector: Injector,
  ) {
    AppInjector.setInjector(injector);
  }
}
