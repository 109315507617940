import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, BehaviorSubject, of, lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Contact } from '@app/models/contact';
import { Card } from '@app/models/card';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { User } from '@app/models/user';
import { CustomerLoyaltyState } from '@app/models/customer-loyalty-state';
import { NavigationState } from '@app/models/navigation-state';
import { InsightsService } from '@app/app-initialisers/insights-service/insights.service';
import { AnalyticsService } from '@app/app-initialisers/analytics-service/analytics.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ApiService } from '@app/api/root/api.service';
import { StorageKeys } from '@app/models/_root/_storage-keys';

/**
 *  Users service that handles everything user
 */
 @Injectable({
   providedIn: 'root'
 })
export class UserService extends ApiService {
  public contact: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(null);
  public card: BehaviorSubject<Card> = new BehaviorSubject<Card>(null);
  public onLoggedOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentUser$: Observable<User>;

  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private router: Router,
    private http: HttpClient,
    private oAuthService: OAuthService,
    private analyticsService: AnalyticsService,
    private readonly insightsService: InsightsService,
    private configurationService: ConfigurationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
  ) {
    super();
    this.currentUser$ = this._user.asObservable();
  }

  public get currentUser(): User {
    return this._user.value;
  }

  /**
   * Gets the user that matches the given id from the api
   * @param {string} userId - the user id
   */
  public getUser(userId: string, refresh?: boolean): Observable<User> {
    if (!userId) {
      return of(null);
    }

    if (this.currentUser && !refresh) {
      return of(this.currentUser);
    }

    return this.getAndTrackUser(userId);
  }

  /**
   * Gets the users loyalty state
   */
  public getUsersLoyalty(): Observable<CustomerLoyaltyState> {
    if (!this.currentUser) {
      const customerLoyaltyState = new CustomerLoyaltyState();
      customerLoyaltyState.PointsBalance = 0;

      return of(customerLoyaltyState);
    }

    return this.http.get<CustomerLoyaltyState>(`${this.configurationService.getApiBaseUrlForTenant()}/customers/${this.currentUser.Id}/loyalty`);
  }

  /**
  * updates the current user stored in memory
  * @param {User} user - the user
  */
  public updateUser(user: User): Observable<User> {
    return this.http
        .put<User>(`${this.configurationService.getApiBaseUrlForTenant()}/customers/${user.Id}`, user)
        .pipe(tap({
          next: (returnedUser: User) => {
            this.storage.set(StorageKeys.user, returnedUser);
            this.setCurrentUser = returnedUser;
          }
        }));
  }

  /**
  * posts a new contact (address) to the api for the given user
  *
  * @param {Contact} contact - the new contact (address)
  * @param {User} userId - the users id
  */
  public newContact(contact: Contact, userId: string): Observable<Contact> {
    return this.http.post<Contact>(`${this.configurationService.getApiBaseUrlForTenant()}/customers/${userId}/contacts`, contact)
        .pipe(tap({ next: (x: Contact) => this.updateContactSubject(x) }));
  }

  /**
  * updates an existing contact (address) for the given user
  * @param {Contact} contact - the updated contact (address)
  * @param {User} userId - the users id
  */
  public updateContact(contact: Contact, userId: string): Observable<Contact> {
    return this.http.put<Contact>(`${this.configurationService.getApiBaseUrlForTenant()}/customers/${userId}/contacts/${contact.Id}`, contact)
        .pipe(tap({ next: (x: Contact) => this.updateContactSubject(x) }));
  }

  /**
  * deletes an existing contact (address) for the given user
  * @param {string} contactId - the contact Id
  * @param {User} userId - the users id
  */
  public removeContact(contactId: string, userId:string): Observable<null> {
    return this.http.delete<null>(`${this.configurationService.getApiBaseUrlForTenant()}/customers/${userId}/contacts/${contactId}`)
        .pipe(tap({ next: () => this.updateContactSubject(null) }));
  }

  /**
   * returns the deletion code needed by the `deleteAccount` method
   */
  public getAccountDeletionCode(): Observable<string> {
    return this.http.get<string>(`${this.configurationService.getApiBaseUrlForTenant()}/customers/${this.currentUser.Id}/deletion-code`);
  }

  /**
   * deletes the users account
   * @param code
   * @param reason
   */
  public deleteAccount(code: string, reason?: string): Observable<null> {
    const params = new HttpParams();
    params.set('code', code);

    if (reason) {
      params.set('reason', reason);
    }

    return this.http.delete<null>(`${this.configurationService.getApiBaseUrlForTenant()}/customers/${this.currentUser.Id}`, { params });
  }

  /**
  * logs out the current user and removes all baskets from local storage
  *
  */
  public logOutUser(removeOnly: boolean = false): void {
    this.storage.remove(StorageKeys.user);
    this.storage.remove(StorageKeys.accessToken);

    for (const key in (this.storage as any)?.storage) {
      if (key.startsWith('basket_')) {
        this.storage.remove(key);
      }
    }

    this.setCurrentUser = null;
    this.insightsService.clearUserId();

    if (!removeOnly) {
      this.oAuthService.logOut();
    }
  }

  /**
  * begins the log in process and sets the location to return the user to
  *
  * @param {boolean} useCurrentRoute - if the user should be returned to the current location
  * @param {string} returnRoute - the route to return the user to once logged in
  */
  public login(useCurrentRoute: boolean = true, returnRoute?: string): void {
    const navigationState = new NavigationState();

    if (useCurrentRoute) {
      navigationState.route = this.router.url;
    } else if (returnRoute && returnRoute !== '') {
      navigationState.route = returnRoute;
    }

    this.storage.set(StorageKeys.loginState, navigationState);

    if (this.oAuthService['inImplicitFlow']) {
      this.insightsService.trackTrace('retrying ImplicitFlow', SeverityLevel.Information),
      this.oAuthService.resetImplicitFlow();
    }
    this.oAuthService.initImplicitFlow();
  }

  public async tryLoginAndSetUser(hash: string): Promise<void> {
    if (hash === '') {
      return;
    }

    await this.oAuthService.tryLogin({
      customHashFragment: hash,
      onLoginError: (err: any) => this.insightsService.trackException(err, false),
      onTokenReceived: () => { }
    });

    if (!this.oAuthService.hasValidAccessToken()) {
      this.oAuthService.silentRefresh()
          .then((info) => console.log('refresh ok', info))
          .catch((err) => console.log('refresh error', err));
      return;
    }

    this.storage.set(StorageKeys.accessToken, this.oAuthService.getAccessToken());
    const profile = await this.oAuthService.loadUserProfile();
    this.setUser(profile['info']);
    this.storage.remove('access_token');
  }

  /**
   * sets the user in the service and stores it in local storage
   * @param profileInfo
   */
  private async setUser(profileInfo: any): Promise<void> {
    if (!profileInfo) {
      this.storage.remove(StorageKeys.user);
      this.storage.remove(StorageKeys.accessToken);
      return;
    }

    await lastValueFrom(this.getAndTrackUser(profileInfo.sub));

    this.navigateToSavedLocation();
  }


  /**
   * updates the value of the user behavior subject to the given value
   *
   * @param {User} user
   * @memberof UserService
   * @public
   */
  private set setCurrentUser(user: User) {
    this._user.next(user);
  }

  /**
  * checks if there's a saved route in local storage from login and if so navigates to it and removes the route from local storage
  */
  private navigateToSavedLocation(): void {
    const navigationState: NavigationState = this.storage.get(StorageKeys.loginState);
    if (navigationState?.route) {
      this.storage.remove(StorageKeys.loginState);
      this.router.navigateByUrl(navigationState.route);
    }
  }

  /**
   * returns the user with the given id from the api and updates the user behavior subject with the returned user.
   * @param userId
   */
  private getAndTrackUser(userId: string): Observable<User> {
    return this.get<User>('body', `${this.configurationService.getApiBaseUrlForTenant()}/customers/${userId}`)
        .pipe(tap({
          next: (returnedUser: User) => {
            if (this._user.value && (JSON.stringify(this._user.value) === JSON.stringify(returnedUser))) {
              return;
            }

            this.storage.set(StorageKeys.user, returnedUser);
            this.insightsService.setUserId(userId);
            this.analyticsService.setupUserId(userId);
            this.setCurrentUser = returnedUser;
          }
        }));
  }

  /**
   * updates the `contact` behavior subject with the given contact
   * @param contact
   */
  private updateContactSubject(contact: Contact): void {
    this.contact.next(contact);
  }
}
