import { Component, Input } from '@angular/core';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Basket } from '@app/models/basket';
import { AnalyticsService } from '@app/app-initialisers/analytics-service/analytics.service';
import { BasketService } from '@app/api/basket.service';
import { BasketDealView } from '@app/models/basket/basket-deal-view';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';

@Component({
  selector: 'app-basket-deal-item',
  styleUrls: ['./basket-deal-item.component.scss', './../../shared-basket-styles.scss'],
  templateUrl: './basket-deal-item.component.html'
})
export class BasketDealItemComponent extends AndroWebCoreComponent {
  @Input() public item: BasketDealView;
  @Input() public basketIsModifiable: boolean;
  @Input() public showImages: boolean = true;

  @Input('currentBasket') private _currentBasket: Basket;

  constructor(
    private _basketService: BasketService,
    private _analyticsService: AnalyticsService
  ) {
    super();
  }

  /**
   * removes an deal from the basket
   * @param dealIndex - the index of the deal
   */
  public async removeDealItem(): Promise<void> {
    await this._basketService.deleteDealFromBasketAsync(this.item.id);
    this.trackRemoveItem();
    await this.removeVoucherFromBasket();
  }

  /**
  * expands or collapses a deal
  * @param dealId - the id of the deal
  * @param dealIndex - the index of the deal
  */
  public toggleDealExpansion(): void {
    this.item.expanded = !this.item.expanded;
  }

  /**
   * tracks the removal of a deal
   */
  private trackRemoveItem(): void {
    const items: GoogleAnalyticsItem[] = [
      {
        'item_id': this.item.dealId,
        'item_name': this.item.name,
        'price': this.item.price,
        'quantity': 1
      }
    ];

    this._analyticsService.trackRemoveFromBasket(this.item.price, items);
  }

  /**
   * removes a voucher from the basket if necessary.
   */
  private async removeVoucherFromBasket(): Promise<void> {
    if (!this._currentBasket.UnlockedProductIds || !this._currentBasket.UnlockedProductIds.includes(this.item.dealId)) {
      return;
    }

    const basket: Basket | null = await this._basketService.deleteVoucherFromBasket(this._currentBasket.Id);

    if (basket) {
      basket.WantedTimeUtc = this._currentBasket.WantedTimeUtc;
      this._currentBasket = basket;
    }
  }
}
