import { IMetricTelemetry } from '@microsoft/applicationinsights-web';

export class MetricTelemetry implements IMetricTelemetry {
  name: string;
  average: number;
  public constructor(name: string, value: number) {
    this.name = name;
    this.average = value;
  }
}
