/**
 * Enumerator for all ChargeTypes
 * @export
 * @enum ChargeTypes
 */
export enum ChargeTypes {
    MinimumSpendSurcharge = 'MinimumSpendSurcharge',
    ProductCharge = 'ProductCharge',
    ChargeableModifier = 'ChargeableModifier',
    Delivery = 'Delivery',
    Tax = 'Tax',
    SubTotal = 'SubTotal',
    SubTotalIncludingTax = 'SubTotalIncludingTax',
    Total = 'Total',
    Discount = 'Discount',
    LoyaltyPointsDiscount = 'LoyaltyPointsDiscount'
  }
