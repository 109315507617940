import { Injectable } from '@angular/core';
import { AllergenDetails } from '@app/models/allergen-details';
import { Basket } from '@app/models/basket';
import { BasketItem } from '@app/models/basket/basket-item';
import { BasketDealItemView } from '@app/models/basket/basket-deal-item-view';
import { BasketDealView } from '@app/models/basket/basket-deal-view';
import { BasketItemModifierChanges } from '@app/models/basket/basket-item-modifier-changes';
import { BasketItemView } from '@app/models/basket/basket-item-view';
import { Charge } from '@app/models/Charge';
import { ChargeTypes } from '@app/models/charge-types';
import { IDictionary } from '@app/models/IDictionary';
import { ModifierType2 } from '@app/models/menu/modifier-type';
import { Modifiers } from '@app/models/Modifiers';
import { OccasionWeekAvailability } from '@app/models/menu/occasion-week-availability';
import { OrderOccasion } from '@app/models/order-occasion';
import { QuantityOf } from '@app/models/quantity-of';
import { WeekPeriod } from '@app/models/week-period';
import { WeekPeriods } from '@app/models/week-periods';
import { isAvailableForDate } from '@app/shared/utils/date-utils';
import { AllergenSummary } from '@app/models/menu/allergen-summary';
import { AllergenSummaryData } from '@app/models/menu/allergen-summary-data';
import { Availability } from '@app/models/menu/availability';
import { Deal } from '@app/models/menu/deal';
import { Menu } from '@app/models/menu/menu';
import { MenuSection } from '@app/models/menu/menu-section';
import { OccasionPrice } from '@app/models/menu/occasion-price';
import { Product } from '@app/models/menu/product';
import { ProductBase } from '@app/models/menu/product-base';
import { ProductVariant } from '@app/models/menu/product-variant';
import { Variant } from '@app/models/menu/variant';
import { ProductOptions } from '@app/models/menu/product-options';
import { CachedMenu } from '@app/models/menu/cached-menu';
import { BasketDeal } from '@app/models/basket/basket-deal';
import { AllergenWithIcon } from '@app/models/menu/allergen-with-icon';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';

@Injectable({
  providedIn: 'root'
})
export class MenuHelperService {
  constructor(
    private _configurationService: ConfigurationService
  ) { }

  public availableForOccasion(values: OccasionWeekAvailability[], occasion: OrderOccasion, wantedTime: Date): boolean {
    return !values || values?.some((value) => this.availableForOccasionAndTime(value, occasion, wantedTime));
  }

  public getProductsForSectionAndOccasion(section: MenuSection, occasion: OrderOccasion, products: Product[]): Product[] {
  // Check if the section is available for the given occasion
    if (this.isAvailableForOccasion(section.Availability, occasion)) {
      return products.filter((product) => section.Products.includes(product.Id));
    }

    // Return an empty array if section is not available for the occasion
    return [];
  }

  public getDealsForSectionAndOccasion(section: MenuSection, occasion: OrderOccasion, deals: Deal[]): Deal[] {
  // Check if the section is available for the given occasion
    if (this.isAvailableForOccasion(section.Availability, occasion)) {
      return deals.filter((deal: Deal) =>
        section.Deals.includes(deal.Id) && deal.Occasions.some((x: OrderOccasion) => x === occasion)
      );
    }

    // Return an empty array if section is not available for the occasion
    return [];
  }

  public getProductSections(menu: Menu, occasion: OrderOccasion): MenuSection[] {
    return menu.Sections.filter((section: MenuSection) =>
      !section.Hidden
        && (this.getProductsForSectionAndOccasion(section, occasion, menu.Products).length > 0
        || this.getDealsForSectionAndOccasion(section, occasion, menu.Deals).length > 0)
    );
  }

  /**
   * Returns the option names for the given variant.
   * @param variant
   * @param options
   */
  public getVariantOptionsPathText(variant: Variant, options: IDictionary<ProductBase>): string {
    return variant.OptionsPath
        .map((id: string) => options[id]?.Name)
        .filter((name: string) => name)
        .join(' - ');
  }

  public getPriceForOccasion(Prices: OccasionPrice[], occasion: OrderOccasion): number {
    return Prices.find((x: OccasionPrice) => x.Occasion === occasion)?.Amount;
  }

  public getDefaultModifiersPriceForOccasion(modifiers: Modifiers, variants: IDictionary<ProductVariant>, occasion: OrderOccasion): number {
    const getModifierPrice = (modifierId: string) => !variants[modifierId] ? 0 : this.getPriceForOccasion(variants[modifierId].Variant.Prices, occasion) ?? 0;
    return modifiers ? modifiers.Default.reduce((acc: number, modifier: string) => acc + getModifierPrice(modifier), 0) : 0;
  }

  public getAllergenSummary(allergens: AllergenDetails): AllergenSummary {
    if (!allergens) {
      return null;
    }

    return {
      summary: this.generateAllergenSummary(allergens),
      showMayContainMessage: Boolean(allergens.MayContain?.length),
      data: this.createAllergenData(allergens)
    };
  }

  public getApplicableProductOptions(productOptions: ProductOptions[], variants: Variant[]): ProductOptions[] {
    const result: ProductOptions[] = [];

    for (const optionId of variants.flatMap((x) => x.OptionsPath)) {
      const options = productOptions.find((x) => x.Options.some((y) => y.Id === optionId));

      if (!options || result.some((x) => x.Id === options.Id)) {
        continue;
      }

      result.push(options);
    }

    return result.map((x: ProductOptions) => ({
      ...x,
      Options: x.Options.filter((y: ProductBase) => variants.some((z: Variant) => z.OptionsPath.includes(y.Id)))
    }));
  }

  public getBasketItemViews(basket: Basket, menu: CachedMenu): { deals: BasketDealView[]; products: BasketItemView[]; } {
    const deals: BasketDealView[] = basket.Deals?.map((deal: BasketDeal) => this.mapBasketDealToDealView(deal, menu, basket.UnlockedProductIds))
        .sort((a: BasketDealView, b: BasketDealView) => a.name.localeCompare(b.name));

    const products: BasketItemView[] = basket.Items?.map((item: BasketItem) => this.mapBasketItemToBasketItemView(item, menu))
        .filter((x: BasketItemView) => x);

    return { deals, products };
  }

  public calculateDealPrice(deal: BasketDeal): number {
    let total = this.calculateDealModifiersTotal(deal.Items) ?? 0;

    deal.Items
        .filter((x: BasketItem) => x.Charges?.length > 0)
        .forEach((item: BasketItem) => {
          item.Charges
              .filter((c: Charge) => c.ChargeType === ChargeTypes.ProductCharge)
              .forEach((c: Charge) => total += c.Total);
        });

    return total;
  }

  public getFriendlyAllergenValue = (values: string[]) => values.map((x) => this.mapAllergenToKnownAllergen(x)).join(', ');

  public getAnalyticsItems(deals: BasketDeal[], products: BasketItem[], menu: Menu): GoogleAnalyticsItem[] {
    const items: GoogleAnalyticsItem[] = [
      ...deals.map((deal: BasketDeal) => {
        const _deal = menu.Deals.find((x: Deal) => x.Id === deal.DealId);
        return {
          item_id: deal.DealId,
          item_name: _deal?.Name,
          price: this.calculateDealPrice(deal),
          quantity: 1
        };
      }),
      ...products.map((item: BasketItem) => {
        const price = item.Charges
            .filter((x: Charge) => x.ChargeType === ChargeTypes.ProductCharge || x.ChargeType === ChargeTypes.ChargeableModifier)
            .reduce((a, b) => a + b.Total, 0);
        const product = menu.Products.find((x: Product) => x.Id === item.Product.Item);

        return {
          item_id: item.Product.Item,
          item_name: product?.Name,
          price,
          quantity: item.Product.Quantity
        };
      })
    ];

    return items;
  }

  private availableForTime(weekAvailability: WeekPeriods, wantedTime: Date): boolean {
    return weekAvailability.Values.some((availability: WeekPeriod) => isAvailableForDate([availability], wantedTime));
  }

  private isAvailableForOccasion(availability: Availability, occasion: OrderOccasion): boolean {
    if (!availability) {
      return true;
    }

    const sectionAvailability = availability.Values.find((x: OccasionWeekAvailability) => x.Occasion === occasion);
    return sectionAvailability === null || !sectionAvailability.Disabled;
  }

  private availableForOccasionAndTime(value: OccasionWeekAvailability, occasion: OrderOccasion, wantedTime: Date): boolean {
    return !value.Disabled && value.Occasion === occasion && this.availableForTime(value.WeekAvailability, wantedTime);
  }

  private generateAllergenSummary(allergens: AllergenDetails): string {
    const containsSummary = allergens.Contains ? this.getFriendlyAllergenValue(allergens.Contains) : '';
    const mayContainSummary = allergens.MayContain ? `(May Contain: ${this.getFriendlyAllergenValue(allergens.MayContain)})` : '';

    if (containsSummary && mayContainSummary) {
      return `${containsSummary}, ${mayContainSummary}`;
    }

    return containsSummary || mayContainSummary;
  }

  private createAllergenData(allergens: AllergenDetails): AllergenSummaryData | undefined {
    if (!allergens.Contains && !allergens.MayContain) {
      return undefined;
    }

    return {
      contains: this.mapAllergensWithIcon(allergens.Contains, 'red'),
      mayContain: this.mapAllergensWithIcon(allergens.MayContain, 'amber')
    };
  }

  private mapAllergensWithIcon(allergens: string[], color: 'red' | 'amber'): AllergenWithIcon[] {
    if (!allergens) {
      return [];
    }

    return allergens.map((x: string) => ({
      imageSource: this.getAllergenImage(x, color),
      name: this.mapAllergenToKnownAllergen(x)
    }));
  }

  private mapAllergenToKnownAllergen(key: string): string {
    const knownAllergens: IDictionary<string> = this._configurationService.getTenantAllergenMap();
    return knownAllergens[key] ? knownAllergens[key].replace('cereals-', '') : key;
  }

  private getAllergenImage(key: string, color: 'red' | 'amber'): string {
    const allergenId = this.mapAllergenId(key);
    return `assets/allergens/${allergenId}/${allergenId}_${color}_100x100.png`;
  }

  private mapAllergenId(key: string): string {
    switch (key) {
      case 'mil':
        return 'dairy';
      case 'cer':
      case 'cer-bar':
      case 'cer-kam':
      case 'cer-oat':
      case 'cer-ogf':
      case 'cer-rye':
      case 'cer-spe':
      case 'cer-whe':
        return 'glu';
      default:
        return key;
    }
  }

  /**
   * Maps a basket deal to a deal view.
   * @param basketDeal
   * @param menu
   * @param unlockedProductIds
   */
  private mapBasketDealToDealView(basketDeal: BasketDeal, menu: CachedMenu, unlockedProductIds?: string[]): BasketDealView {
    const deal: Deal = menu.menu.Deals.find((d: Deal) => d.Id === basketDeal.DealId);

    return {
      id: basketDeal.Id,
      name: deal.Name,
      imageUrl: deal.ImageBase ? `${deal.ImageBase}/landscape-small.jpg` : null,
      isInVoucher: unlockedProductIds?.includes(basketDeal.DealId) ?? false,
      expanded: false,
      items: this.getBasketDealItemViews(basketDeal.Items, menu),
      price: this.calculateDealPrice(basketDeal),
      hasIssues: basketDeal.Items.some((x: BasketItem) => x.Issues?.length > 0),
      dealId: basketDeal.DealId,
      occasions: deal.Occasions
    };
  }

  private getModifierTypesForBasketItem(productVariant: ProductVariant, modifiers: QuantityOf[], variantsById: IDictionary<ProductVariant>): BasketItemModifierChanges {
    if (!productVariant) {
      return null;
    }

    const addons: ModifierType2[] = [];
    const removes: ModifierType2[] = [];
    const modifierIds: string[] = modifiers.map((x: QuantityOf) => x.Item);

    // Removed modifiers
    productVariant.Variant.Modifiers?.Default
        .filter((id: string) => !modifierIds.includes(id))
        .forEach((id: string) => {
          const product: ProductVariant = variantsById[id];

          if (product) {
            removes.push({ quantity: 1, name: product.Product.Name });
          }
        });

    const uniqueModifiers: QuantityOf[] = [];

    modifiers.forEach((modifier: QuantityOf) => {
      const index: number = uniqueModifiers.findIndex((x: QuantityOf) => x.Item === modifier.Item);

      if (index > -1) {
        uniqueModifiers[index].Quantity += 1;
      } else {
        uniqueModifiers.push(modifier);
      }
    });

    uniqueModifiers.forEach((modifier: QuantityOf) => {
      const isDefault: boolean = productVariant.Variant.Modifiers.Default.includes(modifier.Item);

      if (isDefault && modifier.Quantity === 1) {
        return;
      }

      if (isDefault) {
        modifier.Quantity--;
      }

      addons.push({ quantity: modifier.Quantity, name: variantsById[modifier.Item].Product.Name });
    });

    return { addons, removes };
  }

  /**
   * returns basket deal item view from basket items.
   * @param items
   * @param menu
   */
  private getBasketDealItemViews(items: BasketItem[], menu: CachedMenu): BasketDealItemView[] {
    if (!items) {
      return [];
    }

    return items
        .map((item: BasketItem) => {
          const product: ProductVariant = menu.variantsById[item.Product.Item];

          if (!product) {
            return null;
          }

          return {
            name: `${product.Product.Name}${product.Variant.OptionsPath.length > 1 ? `, ${this.getVariantOptionsPathText(product.Variant, menu.optionsById)}` : ''}`,
            imageUrl: product.Product.ImageBase ? `${product.Product.ImageBase}/landscape-small.jpg` : null,
            issues: item.Issues,
            modifiers: this.getModifierTypesForBasketItem(menu.variantsById[item.Product.Item], item.Modifiers, menu.variantsById)
          };
        })
        .filter((x: BasketDealItemView) => x);
  }

  /**
   * Maps a basket item to a basket item view.
   * @param item
   * @param menu
   */
  private mapBasketItemToBasketItemView(item: BasketItem, menu: CachedMenu): BasketItemView {
    const product: ProductVariant = menu.variantsById[item.Product.Item];

    if (!product) {
      return null;
    }

    return {
      id: item.Id,
      productId: product.Product.Id,
      name: product.Product.Name,
      imageUrl: product.Product.ImageBase ? `${product.Product.ImageBase}/landscape-small.jpg` : null,
      quantity: item.Product.Quantity,
      options: product.Variant.OptionsPath.length > 1 ? this.getVariantOptionsPathText(product.Variant, menu.optionsById) : null,
      modifiers: this.getModifierTypesForBasketItem(menu.variantsById[item.Product.Item], item.Modifiers, menu.variantsById),
      price: item.Charges.filter((x: Charge) => x.ChargeType === ChargeTypes.ProductCharge || x.ChargeType === ChargeTypes.ChargeableModifier).reduce((a, b) => a + b.Total, 0),
      issues: item.Issues,
      occasions: product.Variant.Prices.map((x: OccasionPrice) => x.Occasion)
    };
  }

  /**
  * calculates the combined price of all modifiers on a deal
  * @param dealItems - the items (lines) on a deal
  */
  private calculateDealModifiersTotal(dealItems: BasketItem[]): number {
    let number = 0;

    dealItems?.forEach((item: BasketItem) => {
      item.Charges.filter((c: Charge) => c.ChargeType === ChargeTypes.ChargeableModifier)
          .forEach((c: Charge) => number += c.Total);
    });

    return number;
  }
}
