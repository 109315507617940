import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '@app/api/user.service';
import { User } from '@app/models/user';
import { LOCAL_STORAGE } from 'ngx-webstorage-service';
import { map } from 'rxjs/operators';
import { StorageKeys } from '@app/models/_root/_storage-keys';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedInGuard {
  constructor(
    private router: Router,
    private userService: UserService,
    @Inject(LOCAL_STORAGE) private storage: Storage
  ) { }

  public isLoggedIn(state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const accessToken: string = this.storage.get(StorageKeys.accessToken);

    if (!accessToken) {
      return this.triggerLogin(false, state.url);
    }

    if (this.userService.currentUser) {
      return true;
    }

    const tempUser: User = this.storage.get(StorageKeys.user);

    if (tempUser?.Id) {
      return this.getUserById(tempUser.Id);
    }

    return this.triggerLogin();
  }

  private getUserById(tempUserId: string): Observable<boolean | UrlTree> {
    return this.userService.getUser(tempUserId).pipe(
        map((user: User) => user ? true : this.triggerLogin())
    );
  }

  private triggerLogin(useCurrentRoute?: boolean, returnRoute?: string): UrlTree {
    this.userService.login(useCurrentRoute, returnRoute);
    return this.router.createUrlTree(['/']);
  }
}
