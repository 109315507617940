import { HostListener, TemplateRef, Directive } from '@angular/core';
import { ToastsService } from '@app/shared/services/toasts.service';

import {AndroWebCoreBase} from '@app/core/AndroWebCoreBase';
import { AndroWebErrorService } from '@app/core/AndroWebError.service';
import { AppInjector } from '@app/core/injector.core';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { IssueTypes } from '@app/models/issue-types';
import { Tenant } from '@app/models/tenant';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { ScreenSizeService } from '@app/shared/services/screen-size-service/screen-size-service.service';
import { ScreenSizeState } from '@app/models/shared/screen-size-state';
import { Observable, takeUntil } from 'rxjs';
import { replaceZuluTime } from '@app/shared/utils/date-utils';

/**
 * The base component to handle shared functionality
 * @export
 * @abstract
 * @class AndroWebCoreComponent
 */
@Directive()
export abstract class AndroWebCoreComponent extends AndroWebCoreBase {
  public isMobile: boolean;
  public isTablet: boolean;
  public isShortDevice: boolean;

  protected readonly _wantedTimeTypeKey: string = '__&&WantedTimeType&&__';
  protected readonly _sessionPostCodeKey: string = '__&PostCode&__';
  protected readonly _configurationService: ConfigurationService;

  private readonly _dialog: MatDialog;
  private readonly _toastsSerivce: ToastsService;
  private readonly _androWebErrorService: AndroWebErrorService;

  private _tenant: Tenant;
  private _screenSizeService: ScreenSizeService;
  private _screenSizeState: Observable<ScreenSizeState>;

  @HostListener('window:resize')
  onResize() {
    this.mobileWidthCheck();
  }

  protected constructor() {
    super();
    const injector = AppInjector.getInjector();

    this._toastsSerivce = injector.get(ToastsService);
    this._configurationService = injector.get(ConfigurationService);
    this._androWebErrorService = injector.get(AndroWebErrorService);
    this._screenSizeService = injector.get(ScreenSizeService);
    this._tenant = this._configurationService.tenant;
    this._dialog = injector.get(MatDialog);

    this.mobileWidthCheck();
  }

  public get screenSizeState$(): Readonly<Observable<ScreenSizeState>> {
    return this._screenSizeState;
  }

  /**
   * Checks the width of the screen
   * @memberof AndroWebCoreComponent
   * @protected
   */
  protected mobileWidthCheck(): void {
    this._screenSizeState = this._screenSizeService.screenSizeState$;

    this._screenSizeState
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: ScreenSizeState) => {
          this.isMobile = state.isMobile;
          this.isTablet = state.isTablet;
          this.isShortDevice = state.isShortDevice;
        });
  }

  /**
   * Gets the tenant
   * @public
   * @type {Tenant}
   * @memberof AndroWebCoreComponent
   */
  public get tenant(): Tenant {
    return this._tenant;
  }

  public get loadingLogo(): string {
    return this._tenant?.Branding?.BusyIndicatorImageUrl;
  }

  /**
   * Returns an error message
   * @param {IssueTypes} type
   * @memberof AndroWebCoreComponent
   * @protected
   */
  protected getErrorMessage(type: IssueTypes): Promise<string> {
    return this._androWebErrorService.getErrorMessage(type);
  }

  /**
  * opens a mat dialog with the given template
  * @param {TemplateRef<any>} modal - the template
  * @param {string} modalId
  * @param {Partial<MatDialogConfig>} config
  * @protected
  * @memberof AndroWebCoreComponent
  */
  protected openDialog<T = any, D = any>(modal: ComponentType<T> | TemplateRef<T>, modalId: string, config?: Partial<MatDialogConfig<D>>): MatDialogRef<T, any> {
    const dialogRef = this._dialog.getDialogById(modalId);

    if (dialogRef) {
      return dialogRef;
    }

    const options: MatDialogConfig = {
      id: modalId,
      maxWidth: 'unset',
      panelClass: [modalId],
      width: this.isMobile ? '100%' : '80%'
    };

    if (config) {
      const keys: string[] = Object.keys(config);
      for (const key of keys) {
        if (key === 'height' || key === 'width') {
          options[key] = this.isMobile ? '100%' : config[key];
        } else {
          options[key] = config[key];
        }
      }
    }

    return this._dialog.open<T, D>(modal, options);
  }

  /**
   * returns a v4 uuid {@link https://developer.mozilla.org/en-US/docs/Glossary/UUID}
   */
  protected getGuid(): string {
    return crypto.randomUUID();
  }

  /**
  * closes a mat dialog that matches the given id
  * @param id - the dialog id
  * @public
  * @memberof AndroWebCoreComponent
  */
  public closeModalById(id: string): void {
    const dialogRef = this._dialog.getDialogById(id);

    if (dialogRef) {
      dialogRef.close();
    }
  }

  /**
   * Displays a toast to the user
   * @param {ToastTypes} type
   * @param {string} message
   * @param {string} title
   * @param {duration} duration
   * @memberof AndroWebCoreComponent
   * @protected
   */
  protected showToast(type: ToastTypes, message: string, title?: string, duration?: number): void {
    this._toastsSerivce.showToast(type, message, title, duration);
  }

  /**
  * gets the given {IssueTypes} type's error narrative and displays it to the user in a toast
  * @param {IssueTypes} type
  * @param {string} title
  * @param {number} duration
  * @memberof AndroWebCoreComponent
  * @protected
  */
  protected showErrorMessage(type: IssueTypes, fallbackMessage: string, title?: string, duration?: number): void {
    this.getErrorMessage(type).then((message: string) => {
      if (message === 'something has gone wrong' && fallbackMessage) {
        message = fallbackMessage;
      }

      this.showToast(ToastTypes.error, message, title, duration);
    });
  }

  /**
   * Resolves the promise once the given time has passed
   * @param time the amount of time to delay in milliseconds
   * @memberof AndroWebCoreComponent
   * @protected
   */
  protected async setDelay(time: number): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, time));
  }

  /**
   * Returns a date from the given input.
   * @param dateAndTime
   */
  protected getDateFromString(dateAndTime: string | Date): Date {
    if (typeof dateAndTime === 'string') {
      const splitDateAndTime: string[] = this.replaceZuluTime(dateAndTime).split('T');
      const splitDate: string[] = splitDateAndTime[0].split('-');
      const splitTime: string[] = splitDateAndTime[1].split(':');
      const month: number = parseInt(splitDate[1], 10);
      const hours: number = splitTime[0] ? +splitTime[0] : 0;
      const minutes: number = splitTime[1] ? +splitTime[1] : 0;
      const seconds: number = splitTime[2] ? +splitTime[2] : 0;
      const ms: number = splitTime[3] ? +splitTime[3] : 0;
      return new Date(+splitDate[0], month - 1, +splitDate[2], hours, minutes, seconds, ms);
    } else {
      return dateAndTime;
    }
  }

  protected replaceZuluTime(date: string | undefined): string | null {
    return replaceZuluTime(date);
  }
}
