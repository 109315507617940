import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { Site } from '@app/models/site';
import { SiteService } from '@app/api/site.service';

@Injectable()
export class SiteResolver {
  constructor(public siteService: SiteService) { }

  public resolve() : Observable<Site[]> {
    if (!this.siteService.sites.value) {
      return this.siteService.getSites();
    }
    return of(this.siteService.sites.value);
  }
}
