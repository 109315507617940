import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@app/modules/pipes/pipes-module';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { MatSliderModule } from '@angular/material/slider';
import { DealsComponent } from '@app/shared/components/deals/deals.component';
import { WantedTimePickerComponent } from '@app/shared/components/wanted-time/wanted-time-picker/wanted-time-picker.component';
import { BasketComponent } from '@app/shared/components/basket/basket.component';
import { BasketChargesComponent } from '@app/shared/components/basket/components/basket-charges/basket-charges.component';
import { BasketDealItemComponent } from '@app/shared/components/basket/components/basket-deal-item/basket-deal-item.component';
import { BasketLoyaltyComponent } from '@app/shared/components/basket/components/basket-loyalty/basket-loyalty.component';
import { BasketProductItemComponent } from '@app/shared/components/basket/components/basket-product-item/basket-product-item.component';
import { BasketVoucherComponent } from '@app/shared/components/basket/components/basket-voucher/basket-voucher.component';

@NgModule({
  declarations: [
    BasketComponent,
    BasketChargesComponent,
    BasketDealItemComponent,
    BasketProductItemComponent,
    BasketLoyaltyComponent,
    BasketVoucherComponent
  ],
  exports: [
    BasketComponent,
    BasketChargesComponent,
    BasketDealItemComponent,
    BasketProductItemComponent,
    BasketLoyaltyComponent,
    BasketVoucherComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    MatSliderModule,
    WantedTimePickerComponent,
    DealsComponent
  ]
})
export class BasketModule {}
