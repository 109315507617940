import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { IProblemDetails } from '@app/models/IProblemDetails';
import { IssueTypes } from '@app/models/issue-types';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AndroWebErrorService {
  private _allProblemDetails: IProblemDetails[] = [];

  constructor(
    private _http: HttpClient,
    private _configurationService: ConfigurationService,
  ) {
  }

  /**
  * returns an error message for the given errorType
  * @param {IssueTypes} errorType - the type of issue
  */
  public async getErrorMessage(errorType: IssueTypes): Promise<string> {
    if (errorType === IssueTypes.VoucherIsNotValidForProductsInBasket) {
      return this.getFallBackMessage(errorType);
    }

    const details: IProblemDetails[] = await this.getProblemDetails();
    const matchingProblem: IProblemDetails = details.find((e: IProblemDetails) => e.type === errorType || e.title === errorType);
    return matchingProblem?.detail ? matchingProblem.detail : this.getFallBackMessage(errorType);
  }

  /**
  * returns problem details from the api or a local cache
  */
  private async getProblemDetails(): Promise<IProblemDetails[]> {
    if (this._allProblemDetails.length < 0) {
      return Promise.resolve(this._allProblemDetails);
    }

    this._allProblemDetails = await lastValueFrom(this._http.get<IProblemDetails[]>(`${this._configurationService.getApiBaseUrl()}/problemdetails`));

    return this._allProblemDetails;
  }

  /**
  * returns a fallback message for the given errorType
  * @param {IssueTypes} errorType - the type of issue
  */
  private getFallBackMessage(errorType: IssueTypes): string {
    switch (errorType) {
      case IssueTypes.LoyaltyPointsCustomerDoesNotHoldRedeemQuantity:
        return 'You can\'t more loyalty points than you have earned.';
      case IssueTypes.LoyaltyPointsWouldDiscountMoreThanAllowedValue:
        return 'You can\'t redeem this many points.';
      case IssueTypes.SiteIsNotAvailableForAsapOrdersNow:
      case IssueTypes.SiteDoesNotAcceptAsapOrdersForOccasion:
        return 'Sorry, this store is not accepting orders for your wanted time, please select a new time.';
      case IssueTypes.OccasionIsNotAvailableAtWantedTime:
        return 'Sorry, your requested time is no longer available, please select a new time.';
      case IssueTypes.DealMinimumSpendIsLowerThanBasketSpend:
        return 'Sorry, the minimum spend requirement for this deal has not been met';
      case IssueTypes.LoyaltyPointsWouldViolateMinimumRemainingOrderValue:
        return 'Sorry, You can\'t redeem this many points.';
      case IssueTypes.VoucherCodeIsNotValid:
        return 'Sorry, the entered voucher code is not valid.';
      case IssueTypes.VoucherIsNotValidForTimeOfDay:
        return 'This voucher is not available at the selected wanted time.';
      case IssueTypes.VoucherIsNotValidForDayOfWeek:
      case IssueTypes.VoucherIsNotValidForDate:
        return 'This voucher is not available on the selected wanted day.';
      case IssueTypes.VoucherIsNotValidForOccasion:
        return 'This voucher is not available for this occasion.';
      case IssueTypes.VoucherIsNotValidForBasketValue:
        return 'This voucher cannot be applied at this basket price.';
      case IssueTypes.VoucherIsNotValidForSite:
        return 'This voucher is not valid at this store.';
      case IssueTypes.VoucherUsageWouldBeExceeded:
        return 'You have already redeemed this voucher.';
      case IssueTypes.VoucherIsNotValidWithDeals:
        return 'This voucher is not valid with deals.';
      case IssueTypes.VoucherIsNotValidForProductsInBasket:
        return 'This voucher is not valid for any products currently in the basket.';
      default: {
        return 'something has gone wrong';
      }
    }
  }
}


