import { Component, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-androweb-input',
  styleUrls: ['./androweb-input-control.component.scss'],
  templateUrl: './androweb-input-control.component.html'
})
export class AndrowebInputControlComponent implements ControlValueAccessor {
  /**
   * Specifies the label to be displayed to the user.
   */
  @Input() public label: string;

  /**
   * Specifies a short hint that describes the expected value.
   */
  @Input() public placeHolder: string;

  /**
   * Specifies the text to display when the value of the input does not pass the pattern specified
   * in the form builder.
   */
  @Input() public patternError?: string;

  /**
   * Specifies the type <input> element to display or whether a textarea should.
   * type should equal `textarea` for a textarea.
   */
  @Input() public type: string = 'text';

  /**
   * Specifies the maximum number of characters allowed.
   */
  @Input() public maxLength: number = 1000;

  /**
   * If true, any errors will display to the user, even if the control has NOT been touched.
   */
  @Input() public showInputError: boolean = false;

  @Input() public matIcon: string;
  @Input() public prependIconBackground: string = '#f5f5f5';
  @Input('text-transform') public textTransform: string = 'none';

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Writes a new value to the element.
   *
   * This method is called by the forms API to write to the view when programmatic
   * changes from model to view are requested.
   * @param obj The new value for the element
   */
  public writeValue(obj: any): void {
  }

  /**
   * Registers a callback function that is called when the control's value
   * changes in the UI.
   *
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   * @param fn The callback function to register
   */
  public registerOnChange(fn: any): void {
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   * @param fn The callback function to register
   */
  public registerOnTouched(fn: any): void {
  }
}
