import { Component, OnInit, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { interval, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@app/api/user.service';
import { NavigatorService } from '@app/core/navigator.service';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-identity-iframe',
  styleUrls: ['./identity-iframe.component.scss'],
  templateUrl: './identity-iframe.component.html',
})
export class IdentityIframeComponent extends AndroWebCoreComponent implements OnInit {
  public isLoading: boolean = true;

  public identitySource: string;

  private identityIntervalSubscription: Subscription;

  private iframe: HTMLIFrameElement;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private navigatorService: NavigatorService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.setIframeSrc();
  }

  /**
   * sets the src of the iframe
   */
  private setIframeSrc(): void {
    this.iframe = (document.getElementById('identityFrame') as HTMLIFrameElement);
    this.iframe.src = this.storage.get('identitySrc');
    this.storage.remove('identitySrc');
  }

  /**
   * Checks if the identity frame location includes a hash or a href and if so tries to log in/out the user and
   * closes the modal
   */
  public identityIframeLoad(): void {
    this.isLoading = false;

    if (!this.identityIntervalSubscription) {
      this.identityIntervalSubscription = interval().subscribe(async () => {
        let hash: string;
        let href: string;

        try {
          hash = this.iframe?.contentWindow?.location?.hash;
          href = this.iframe?.contentWindow?.location?.href;

          if (href?.includes('/authComplete')) {
            this.identityIntervalSubscription.unsubscribe();
            this.closeIdentityModal();

            if (window.location.href.includes('/checkout') || window.location.href.includes('/profile')) {
              this.trackTrace('logged out successfully, rerouting home', SeverityLevel.Information);
              this.router.navigate([this.navigatorService.generateHomePath()]);
            }

            if (hash) {
              if (hash.includes('error_description=cancel')) {
                this.trackTrace('User cancelled login', SeverityLevel.Information);
              } else {
                this.trackTrace('User completed login', SeverityLevel.Information);
                this.userService.tryLoginAndSetUser(hash);
              }
            }
          } else if (hash && hash.includes('id_token=')) {
            await this.userService.tryLoginAndSetUser(hash);
            this.identityIntervalSubscription.unsubscribe();
            this.closeIdentityModal();
          }

          if (href?.includes('/profile?view=account') || href === location.origin) {
            this.identityIntervalSubscription.unsubscribe();
            this.closeIdentityModal();
          }
        } catch (e) { }
      });
    }
  }

  /**
   * closes the mat diagram with a matching id of 'identity-modal'
   */
  private closeIdentityModal(): void {
    const dialogRef: MatDialogRef<any, any> = this.dialog.getDialogById('identity-modal');

    if (dialogRef) {
      dialogRef.close();
    }
  }
}
