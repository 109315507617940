import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { StorageKeys } from '@app/models/_root/_storage-keys';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private configurationService: ConfigurationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.storage.get(StorageKeys.accessToken);
    const route: string = this.configurationService.getEnvironmentAppSlot();
    const headers: { [name: string]: string | string[] } = { 'ContentType': 'application/json' };

    if (!request.url.startsWith(' https://api.getAddress.io') && !request.url.startsWith('https://maps.googleapis.com')) {
      headers['Access-Control-Allow-Origin'] = '*';

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      if (route) {
        headers['x-ms-routing-name'] = route;
      }

      if (token || route) {
        request = request.clone({ setHeaders: headers });
      }
    }

    return next.handle(request);
  }
}
