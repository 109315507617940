/**
 * Enumerator for all OrderOccasion
 * @export
 * @enum OrderOccasion
 */
export enum OrderOccasion {
    Collection = "Collection",
    Delivery = "Delivery",
    DineIn = "DineIn",
    TakeAway = "TakeAway"
  }
