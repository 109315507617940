import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { DateTimeSplit } from '@app/models/date-time-split.enum';
import { ICalendarDateGroup } from '@app/models/wanted-time-picker/ICalendarDateGroup';
import { INewTimeTimes } from '@app/models/wanted-time-picker/INewTimeTimes';
import { SharedMaterialModule } from '@app/shared/shared-material.module';

@Component({
  imports: [CommonModule, SharedMaterialModule],
  selector: 'app-future-wanted-time-picker',
  standalone: true,
  styleUrls: ['./future-wanted-time-picker.component.scss'],
  templateUrl: './future-wanted-time-picker.component.html',
})
export class FutureWantedTimePickerComponent extends AndroWebCoreComponent implements OnInit {
  @Output('onConfirmSelection') private _onConfirmSelection: EventEmitter<string>;
  @Input('slots') private _slots: INewTimeTimes[];
  @Input('wantedTimeUtc') private _wantedTimeUtc: string;
  @Input('useBasketForInitialTime') private _useBasketForInitialTime: boolean = true;

  public selectedTime: string;
  public timeSlots: INewTimeTimes[];
  public isLoading: boolean;
  public calendarDateGroup: ICalendarDateGroup[];

  private _selectedDate: string;

  constructor(
    private _datePipe: DatePipe,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this._onConfirmSelection = new EventEmitter<string>();
  }

  ngOnInit() {
    this.initializeTimePicker();
  }

  /**
   * handles the date picked event
   * @param event
   */
  public orderDatePicked(event: MatSelectChange): void {
    this.selectedDate = event.value;
    this.setFirstSlot();
  }

  /**
   * sets the selected time slot
   * @param timeSlot
   */
  public setSelectedSlot(timeSlot: string, scrollIntoView: boolean = true): void {
    this.selectedTime = timeSlot;

    if (scrollIntoView) {
      this.scrollSelectedSlotIntoView();
    }
  }

  /**
   * confirms the selected date and time
   */
  public confirmSelection(): void {
    this._onConfirmSelection.emit(`${this.selectedDate}T${this.selectedTime}`);
    this.closeModalById('future-wanted-time-modal');
  }

  /**
   * gets the selected date
   */
  public get selectedDate(): string {
    return this._selectedDate;
  }

  /**
   * initializes the time picker
   */
  private initializeTimePicker(): void {
    this.calendarDateGroup = this.mapSlotsToGroup(this._slots);

    this.selectedDate = this.calendarDateGroup[0]?.calendarDate ?? '';
    this.setFirstSlot();

    if (this._wantedTimeUtc && this._useBasketForInitialTime) {
      const wantedDate = new Date(this.replaceZuluTime(this._wantedTimeUtc));
      this.selectedDate = this.getStringFromDate(wantedDate, DateTimeSplit.date);
      this.setSelectedSlot(this.getStringFromDate(wantedDate, DateTimeSplit.time));
    }
  }

  /**
   * sets the first slot as the selected slot
   */
  private setFirstSlot(): void {
    if (!this.timeSlots || this.timeSlots.length === 0) {
      return;
    }

    this.setSelectedSlot(this.timeSlots[0].time);
  }

  /**
   * sets the selected date and updates the time slots for the new updated date
   */
  private set selectedDate(value: string) {
    this._selectedDate = value;
    this.timeSlots = this.calendarDateGroup.find((x: ICalendarDateGroup) => x.calendarDate === this.selectedDate)?.values;
  }

  /**
   * scrolls the selected slot into view.
   */
  private scrollSelectedSlotIntoView(): void {
    if (!this._changeDetectorRef['destroyed']) {
      this._changeDetectorRef.detectChanges();
    }

    document.getElementById(`slot-${this.selectedTime}`)?.scrollIntoView();
  }

  /**
   * maps the slots to a group of dates
   * @param slots
   */
  private mapSlotsToGroup(slots: INewTimeTimes[]): ICalendarDateGroup[] {
    const result: ICalendarDateGroup[] = [];

    slots.forEach((x: INewTimeTimes) => {
      let group: ICalendarDateGroup = result.find((y: ICalendarDateGroup) => y.calendarDate === x.calendarDate);

      if (!group) {
        group = { calendarDate: x.calendarDate, formattedDate: this.formatDate(x.calendarDate), values: [] };
        result.push(group);
      }

      group.values.push(x);
    });

    return result;
  }

  /**
   * formats the date to a readable string format
   * @param calendarDate
   */
  private formatDate(calendarDate: string): string {
    const date = new Date(calendarDate);
    const isTomorrowDate = new Date().getDate() + 1 === date.getDate();
    return `${isTomorrowDate ? '(Tomorrow) ' : ''}${this._datePipe.transform(new Date(calendarDate), 'EEEE d LLLL yyyy')}`;
  }
}
