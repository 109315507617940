import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';

export class PageViewTelemetry implements IPageViewTelemetry {
  name?: string;
  uri?: string;
  pageType?: string;

  public constructor(url: string, pageName: string) {
    this.uri = url;
    this.pageType = pageName,
    this.name = pageName;
  }
}
