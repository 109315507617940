import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Contact } from '@app/models/contact';
import { User } from '@app/models/user';
import { UserService } from '@app/api/user.service';
import { HttpClient } from '@angular/common/http';
import { FormattedAddress } from '@app/models/FormattedAddress';
import { AddressIOResponse } from '@app/models/address-io-response';
import { AddressInputUpdate } from '@app/models/address-input-update';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { ActionButtonsState } from '@app/models/ActionButtonsState';

const getAddressIOApiKey: string = 'UXXIbhqqDUeKewAVR6H3Tw29363';
const getAddressIOFindUrl: string = 'https://api.getAddress.io/find';

/**
 * Shared component for adding new addresses (contact)
 */
@Component({
  selector: 'app-address-input',
  styleUrls: ['./address-input.component.scss'],
  templateUrl: './address-input.component.html'
})
export class AddressInputComponent extends AndroWebCoreComponent implements OnInit {
  @Output() private onClose: EventEmitter<null>;
  @Output() private onCancel: EventEmitter<null>;
  @Output() private updates: EventEmitter<AddressInputUpdate>;
  @Output() private onError?: EventEmitter<Error>;
  @Output() private onCreate: EventEmitter<Contact>;
  @Output() private onUpdate: EventEmitter<Contact>;

  @Input() public showNotes: boolean;
  @Input() public addPadding: boolean;
  @Input() public showExpandedAddress: boolean;
  @Input() public showAddressName: boolean = true;
  @Input() public isBlankAddress?: boolean;
  @Input() public isBillingAddress: boolean;
  @Input() public showStickyFooter: boolean;
  @Input() public showRequiredRule: boolean = true;
  @Input() public saveVisibilityState: ActionButtonsState = 'Visible';
  @Input() public defaultAddressName: string = 'Home';
  @Input() public submitButtonName: string;
  @Input() public mobilePhonePlaceholder: string = 'Needed for order tracking';

  @Input() private contact: Contact;

  public isSaving: boolean;
  public isEditing: boolean;
  public postcodeSearchComplete: boolean;
  public defaultErrorTitle: string = 'Something\'s gone wrong';
  public contactForm: UntypedFormGroup;
  public addressesForPostCode: FormattedAddress[] = [];
  public storedPostCode: string;
  public showValidationErrors: boolean;

  private user: User;

  constructor(
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    super();

    this.onClose = new EventEmitter<null>();
    this.onCancel = new EventEmitter<null>();
    this.updates = new EventEmitter<AddressInputUpdate>();
    this.onCreate = new EventEmitter<Contact>();
    this.onUpdate = new EventEmitter<Contact>();
  }

  ngOnInit(): void {
    this.setUser();
    this.setupForm();
    this.isEditing = !!this.contact;
  }

  /**
   * returns the title for the save button.
   */
  public get saveButtonTitle(): string {
    return this.submitButtonName ?? (this.isEditing && !this.isBlankAddress ? 'Update' : 'Save');
  }

  /**
  * creates a new contact with the details filled in on the contact form
  *
  * @public
  * @memberof AddressInputComponent
  */
  public createContact(): void {
    this.isSaving = true;

    this.contactForm.patchValue({
      Email: this.user.Email,
      FirstName: this.user.FirstName,
      LastName: this.user.LastName,
    });

    const contact: Contact = this.contactForm.value;
    contact.Nickname = this.setAliasCounter(contact.Nickname, contact.Id);

    this.subscriptions$['AddressInputComponent-createContact-userService-newContact'] =
      this.userService.newContact(contact, this.user.Id).subscribe(
          {
            error: (error) => {
              this.trackException(error, false);
              this.onError?.emit(error);
              this.isSaving = false;
              this.showToast(ToastTypes.error, 'Couldn\'t add address', this.defaultErrorTitle);
            },
            next: (data: Contact) => {
              this.user.Contacts.push(data);
              this.onCreate.emit(data);
              this.isSaving = false;
              this.close();
            }
          }
      );
  }

  /**
  * updates the selected contact with the details filled in on the contact form
  *
  * @public
  * @memberof AddressInputComponent
  */
  public editContact(): void {
    this.isSaving = true;

    this.contactForm.patchValue({
      Email: this.user.Email,
      FirstName: this.user.FirstName,
      LastName: this.user.LastName,
    });

    const contact: Contact = this.contactForm.value;
    contact.Nickname = this.setAliasCounter(contact.Nickname, contact.Id);

    this.subscriptions$['ProfileComponent-editContact-userService-updateContact'] =
      this.userService.updateContact(contact, this.user.Id).subscribe(
          {
            error: (error) => {
              this.trackException(error, false);
              this.onError.emit(error);
              this.isSaving = false;
              this.showToast(ToastTypes.error, 'Couldn\'t update address', this.defaultErrorTitle);
            },
            next: (data: Contact) => {
              this.user.Contacts[this.user.Contacts.findIndex((c: Contact) => c.Id === this.contactForm.value.Id)] = data;
              this.onUpdate.emit(data);
              this.isSaving = false;
              this.close();
            }
          }
      );
  }

  /**
   * emits an event that should trigger closing of the component.
   *
   * @public
   * @memberof AddressInputComponent
   */
  public close(): void {
    this.onClose.emit();
  }

  /**
   * emits an event that should trigger cancelling of the form.
   *
   * @public
   * @memberof AddressInputComponent
   */
  public cancelClicked(): void {
    this.onCancel.emit();
    this.close();
  }

  public saveClicked(): void {
    if (!this.contactForm.valid) {
      this.showExpandedAddress = true;
      this.showValidationErrors = true;
      // let the ui pick up the changes before searching for them.
      const delay: number = 100;
      setTimeout(() => {
        const test = document.getElementsByClassName('input-control-with-errors');
        test?.item(0)?.scrollIntoView({ behavior: 'smooth' });
      }, delay);
      return;
    }

    this.isEditing ? this.editContact() : this.createContact();
  }

  /**
   * gets addresses for a given postcode from getAddress.io
   *
   * @param postcode
   * @public
   * @memberof AddressInputComponent
   */
  public getAddressFromPostCode(postcode: string): void {
    if (!postcode || postcode.trim() === '') {
      return;
    }

    this.postcodeSearchComplete = false;
    postcode = postcode.split(' ').join('');

    this.http.get<AddressIOResponse>(`${getAddressIOFindUrl}/${postcode}?api-key=${getAddressIOApiKey}&expand=true`)
        .subscribe(
            {
              error: (error) => {
                this.showToast(ToastTypes.error, 'Sorry we couldn\'t find your address, please enter it manually', this.defaultErrorTitle);
                this.trackException(error, false);
              },
              next: (response: AddressIOResponse) => {
                this.postcodeSearchComplete = true;
                this.addressesForPostCode = response?.addresses ? this.sortFormattedAddresses(response.addresses) : [];
              }
            }
        );
  }

  /**
   * updates the contactForm with the values from the selected address
   *
   * @param $event
   * @param postcode
   * @public
   * @memberof AddressInputComponent
   */
  public selectAddress(value: FormattedAddress, postcode: string): void {
    const maxLength: number = 50;
    this.contactForm.patchValue({
      AddressLine1: (`${value.line_1}, ${value.line_2}`).substring(0, maxLength),
      AddressLine2: (`${value.line_3}${value.line_3 && value.locality ? `, ${ value.locality}` : value.locality}`).substring(0, maxLength),
      PostCode: postcode,
      Town: value.town_or_city,
    });
    this.showExpandedAddress = true;
  }

  /**
   * sets the user in the component to the value of the currentUser$
   *
   * @private
   * @memberof AddressInputComponent
   */
  private setUser(): void {
    this.subscriptions$['ProfileComponent-setUser-userService-currentUser$'] =
      this.userService.currentUser$.subscribe((user: User) => this.user = user);
  }

  /**
  * filters through all contacts to check if the Nickname has been used before.
  * In the case that it has it'll add a period and a number to the contact Nickname
  * in order not to clash with any existing contact as required by their Unique nature.
  * The updated nickname is then returned
  *
  * @param {string} alias - the nickname to check
  * @param {string} contactId - the contact id
  * @private
  * @memberof AddressInputComponent
  */
  private setAliasCounter(alias: string, contactId: string): string {
    const contact: Contact = this.user.Contacts.find((c: Contact) => c.Id === contactId);

    if (contact?.Nickname?.includes(alias)) {
      if (alias.length === contact.Nickname.length) {
        return contact.Nickname;
      }
    }

    const positionsTaken: number[] = [];

    this.user.Contacts.filter((c: Contact) => c.Nickname.includes(alias)).forEach((c: Contact) => {
      const contactTokens: string[] = c.Nickname.split('.', 2);

      if (contactTokens.length === 2) {
        positionsTaken.push(+contactTokens[1]);
      }
    });

    return `${alias}.${this.firstMissingPositive(positionsTaken)}`;
  }

  /**
  * Takes in an array of numbers and returns the largest number plus 1
  *
  * @param nums - the array of numbers
  * @private
  * @memberof AddressInputComponent
  */
  private firstMissingPositive(nums: number[]): number {
    const swap = (i: number, j: number) => {
      const tmp = nums[i];
      nums[i] = nums[j];
      nums[j] = tmp;
    };

    for (let i = 0; i < nums.length; i++) {
      while (0 < nums[i] && nums[i] - 1 < nums.length && nums[i] !== i + 1 && nums[i] !== nums[nums[i] - 1]) {
        swap(i, nums[i] - 1);
      }
    }

    for (let i = 0; i < nums.length; i++) {
      if (nums[i] !== i + 1) {
        return i + 1;
      }
    }
    return nums.length + 1;
  }

  /**
   * Initial setup of the form
   */
  private setupForm(): void {
    this.storedPostCode = this.storage.get(this._sessionPostCodeKey);
    this.storage.remove(this._sessionPostCodeKey);

    this.contactForm = this.formBuilder.group({
      AddressLine1: [this.contact?.AddressLine1 ?? '', [Validators.required, Validators.maxLength(50)]],
      AddressLine2: [this.contact?.AddressLine2 ?? '', [Validators.maxLength(50)]],
      CountryCode: this.contact?.CountryCode ?? 'GB',
      DeliveryNote: [this.contact?.DeliveryNote ?? '', [Validators.maxLength(85)]],
      Email: this.contact?.Email ?? '',
      FirstName: this.contact?.FirstName ?? '',
      Id: this.contact?.Id ?? '',
      IsDefault: this.contact?.IsDefault ?? '',
      LastName: this.contact?.LastName ?? '',
      MobilePhone: [
        this.contact?.MobilePhone ?? '',
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.pattern(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/)
        ]
      ],
      Nickname: [
        this.contact?.Nickname.split('.', 1)[0] ?? this.defaultAddressName,
        [
          Validators.required,
          Validators.maxLength(80)
        ]
      ],
      PostCode: [
        (this.contact?.PostCode ? this.contact.PostCode : this.storedPostCode) ?? '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(
              /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/i
          )
        ]
      ],
      Town: [this.contact?.Town ?? '', [Validators.required, Validators.maxLength(50)]]
    });

    this.subscriptions$['AddressInputComponent-ngOnInit-contactForm-valueChanges'] =
      this.contactForm.valueChanges.subscribe((event: Contact) => {
        this.updates.emit({ contact: event, valid: this.contactForm.valid });
      });
  }

  /**
   * returns the given values sorted sorted by their building and/or house/flat number
   *
   * @param values
   * @private
   * @memberof AddressInputComponent
   */
  private sortFormattedAddresses(values: FormattedAddress[]): FormattedAddress[] {
    // sort alphabetically
    const alphabetically: FormattedAddress[] = values.sort((a: FormattedAddress, b: FormattedAddress) => {
      if (a.line_1 > b.line_1) {
        return 1;
      } else if (a.line_1 < b.line_1) {
        return -1;
      }
      return 0;
    });

    // sort by sub building number then building number
    const hasBoth: FormattedAddress[] = alphabetically.filter((x: FormattedAddress) => !!x.sub_building_number && !!x.building_number)
        .sort((a: FormattedAddress, b: FormattedAddress) => {
          if (Number(a.sub_building_number) > Number(b.sub_building_number)) {
            return 1;
          } else if (Number(a.sub_building_number) < Number(b.sub_building_number)) {
            return -1;
          }
          return 0;
        })
        .sort((a: FormattedAddress, b: FormattedAddress) => {
          if (Number(a.building_number) > Number(b.building_number)) {
            return 1;
          } else if (Number(a.building_number) < Number(b.building_number)) {
            return -1;
          }
          return 0;
        });

    // sort by sub building number only if building number not available
    const hasOnlySubBuilding: FormattedAddress[] = alphabetically.filter((x: FormattedAddress) => !!x.sub_building_number && !x.building_number)
        .sort((a: FormattedAddress, b: FormattedAddress) => {
          if (Number(a.sub_building_number) > Number(b.sub_building_number)) {
            return 1;
          } else if (Number(a.sub_building_number) < Number(b.sub_building_number)) {
            return -1;
          }
          return 0;
        });

    // sort by building number only if sub building number not available
    const hasOnlyBuilding: FormattedAddress[] = alphabetically.filter((x: FormattedAddress) => !x.sub_building_number && !!x.building_number)
        .sort((a: FormattedAddress, b: FormattedAddress) => {
          if (Number(a.building_number) > Number(b.building_number)) {
            return 1;
          } else if (Number(a.building_number) < Number(b.building_number)) {
            return -1;
          }
          return 0;
        });

    // do nothing with the outliers as already sorted alphabetically
    const hasNeither = alphabetically.filter((x) => !x.sub_building_number && !x.building_number);

    return hasNeither.concat(hasBoth).concat(hasOnlySubBuilding).concat(hasOnlyBuilding);
  }
}
