import { ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';

export class TraceTelemetry implements ITraceTelemetry {
  message: string;
  severityLevel?: SeverityLevel;

  public constructor(message: string, severityLevel: SeverityLevel) {
    this.message = message;
    this.severityLevel = severityLevel;
  }
}
