/**
 * Enumerator for all IssueTypes
 * @export
 * @enum IssueTypes
 */
export enum IssueTypes {
  BasketIsCheckedOutAndCannotBeChanged = 'BasketIsCheckedOutAndCannotBeChanged',
  CustomerIsNotAssignedToBasket = 'CustomerIsNotAssignedToBasket',
  DealDoesNotExist = 'Deal Does Not Exist',
  DealIsNotAvailableAtWantedTime = 'Deal Is Not Available At Wanted Time',
  DealIsNotAvailableForOccasion = 'Deal Is Not Available For Occasion',
  DealLineDoesNotExist = 'Deal Line Does Not Exist',
  DealLineHasNonUnaryQuantity = 'Deal Line Has Non Unary Quantity',
  DealLineProductIsNotPartOfDeal = 'Deal Line Product Is Not Part Of Deal',
  DealLinesMissing = 'Deal Lines Missing',
  DealMinimumSpendIsLowerThanBasketSpend = 'DealMinimumSpendIsLowerThanBasketSpend',
  DeliveryOccasionRequiresDeliveryLocation = 'DeliveryOccasionRequiresDeliveryLocation',
  LoyaltyPointsAddedButTenantHasNoLoyaltyScheme = 'LoyaltyPointsAddedButTenantHasNoLoyaltyScheme',
  LoyaltyPointsCustomerDoesNotHoldEnoughToRedeem = 'LoyaltyPointsCustomerDoesNotHoldEnoughToRedeem',
  LoyaltyPointsCustomerDoesNotHoldRedeemQuantity = 'LoyaltyPointsCustomerDoesNotHoldRedeemQuantity',
  LoyaltyPointsNotLargeEnoughToAffectValue = 'LoyaltyPointsNotLargeEnoughToAffectValue',
  LoyaltyPointsWouldDiscountMoreThanAllowedPercentage = 'LoyaltyPointsWouldDiscountMoreThanAllowedPercentage',
  LoyaltyPointsWouldDiscountMoreThanAllowedValue = 'LoyaltyPointsWouldDiscountMoreThanAllowedValue',
  LoyaltyPointsWouldViolateMinimumRemainingOrderValue = 'LoyaltyPointsWouldViolateMinimumRemainingOrderValue',
  ModifierProductDoesNotExist = 'Modifier Product Does Not Exist',
  ModifierProductDoesNotHavePriceForOccasion = 'Modifier Product Does Not Have Price For Occasion',
  ModifierProductIsNotAvailableAtWantedTime = 'ModifierProductIsNotAvailableAtWantedTime',
  OccasionIsNotAvailableAtWantedTime = 'OccasionIsNotAvailableAtWantedTime',
  ProductDoesNotAllowModifier = 'Product Does Not Allow Modifier',
  ProductDoesNotExist = 'Product Does Not Exist',
  ProductDoesNotHavePriceForOccasion = 'Product Does Not Have Price For Occasion',
  ProductIsNotAvailableAtWantedTime = 'Product Is Not Available At Wanted Time',
  ProductMaxModifiersExceeded = 'Product Max Modifiers Exceeded',
  ProductMaxRepetitionsPerModifierExceeded = 'Product Max Repetitions Per Modifier Exceeded',
  SiteDoesNotAcceptAsapOrdersForOccasion = 'SiteDoesNotAcceptAsapOrdersForOccasion',
  SiteDoesNotDeliverToDeliveryLocation = 'SiteDoesNotDeliverToDeliveryLocation',
  SiteIsNotAvailableForAsapOrdersNow = 'SiteIsNotAvailableForAsapOrdersNow',
  TenantDoesNotMatchBasket = 'TenantDoesNotMatchBasket',
  TotalIsLessThanMinimumSpend = 'TotalIsLessThanMinimumSpend',
  VoucherCodeIsNotValid = 'VoucherCodeIsNotValid',
  VoucherIsAlreadyAssignedToBasket = 'VoucherIsAlreadyAssignedToBasket',
  VoucherIsNotAssignedToBasket = 'VoucherIsNotAssignedToBasket',
  VoucherIsNotValidForBasketValue = 'VoucherIsNotValidForBasketValue',
  VoucherIsNotValidForDate = 'VoucherIsNotValidForDate',
  VoucherIsNotValidForDayOfWeek = 'VoucherIsNotValidForDayOfWeek',
  VoucherIsNotValidForOccasion = 'VoucherIsNotValidForOccasion',
  VoucherIsNotValidForSite = 'VoucherIsNotValidForSite',
  VoucherIsNotValidForTimeOfDay = 'VoucherIsNotValidForTimeOfDay',
  VoucherUsageWouldBeExceeded = 'VoucherUsageWouldBeExceeded',
  VoucherIsNotValidWithDeals = 'VoucherIsNotValidWithDeals',
  VoucherIsNotValidForProductsInBasket = 'VoucherIsNotValidForProductsInBasket'
}
