import { IEventTelemetry } from '@microsoft/applicationinsights-web';

export abstract class BasketTelemetry implements IEventTelemetry {
  name = 'Basket';
  properties?: { [key: string]: any; };
  measurements?: { [key: string]: number; };

  protected constructor(
    protected readonly basketId: string,
    protected readonly action: string,
  ) {
    this.properties = [
      { 'Basket Id': basketId },
      { 'Action': action },
    ];
  }
}
