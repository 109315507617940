import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StringToMoneyFilter } from '@app/modules/pipes/string-to-money.pipe';
import { DateTimeFormatFilter } from '@app/modules/pipes/date-time-format-filter';
import { EscapeHtmlPipe } from '@app/modules/pipes/escape-html.pipe';
import { FormControlPipe } from '@app/modules/pipes/form-control-pipe/formControl.pipe';

@NgModule({
  declarations: [
    DateTimeFormatFilter,
    EscapeHtmlPipe,
    StringToMoneyFilter,
    FormControlPipe
  ],
  exports: [
    DateTimeFormatFilter,
    EscapeHtmlPipe,
    StringToMoneyFilter,
    FormControlPipe
  ],
  providers: [
    EscapeHtmlPipe,
    DatePipe
  ]
})
export class PipesModule {}
