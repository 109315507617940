import {AndroWebCoreBase} from '@app/core/AndroWebCoreBase';
import {OnDestroy} from '@angular/core';

/**
 * Handles common fuctionality that may occurer in services
 * @export
 * @abstract
 * @class AndroWebCoreService
 * @extends {AndroWebCoreBase}
 */
export abstract class AndroWebCoreService extends AndroWebCoreBase {
  protected constructor() {
    super();
  }
}
