export class NavigationState {

  public route: string;
  public basketOpen: boolean;
  public routeExtras: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}
