/**
 * Enumerator of all the deal line types
 * @export
 * @enum DealLineType
 */
export enum DealLineType {
    Percent = 'Percent',
    Fixed = 'Fixed',
    Discount = 'Discount'
}
