import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AndroWebCoreService } from '@app/core/AndroWebCoreService';
import { AppInjector } from '@app/core/injector.core';
import { IDictionary } from '@app/models/IDictionary';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends AndroWebCoreService {
  private readonly _httpClient: HttpClient;

  constructor() {
    super();
    const injector = AppInjector.getInjector();
    this._httpClient = injector.get(HttpClient);
  }

  /**
   * The GET request call
   * @param uri The request URL
   * @param observe The preferred type of response
   * @param params The HttpParams object
   * @protected
   * @memberof ApiService
   */
  protected get<T>(
      observe: 'response' | 'body',
      uri: string,
      params?: HttpParams,
      headers?: IDictionary<string | string[]>
  ): Observable<(T | HttpResponse<T>) | HttpErrorResponse> {
    if (!uri || uri.trim() === '') {
      throw new Error('uri is required');
    }

    if (observe === 'response') {
      return this._httpClient.get<T>(uri, { headers, observe: 'response', params })
          .pipe(catchError((error: HttpErrorResponse) => this.trackError(error)));
    } else {
      return this._httpClient.get<T>(uri, { headers, observe: 'body', params })
          .pipe(catchError((error: HttpErrorResponse) => this.trackError(error)));
    }
  }

  /**
   * The PUT request call
   * @param uri The request URL
   * @param observe The preferred type of response
   * @param params Parameters to go with the request
   * @protected
   * @memberof ApiService
   */
  protected put<T, D>(observe: 'response' | 'body', uri: string, body?: D, params?: HttpParams): Observable<(T | HttpResponse<T>) | HttpErrorResponse> {
    if (!uri || uri.trim() === '') {
      throw new Error('uri is required');
    }

    if (observe === 'response') {
      return this._httpClient.put<T>(uri, body, { observe: 'response', params })
          .pipe(catchError((error: HttpErrorResponse) => this.trackError(error)));
    } else {
      return this._httpClient.put<T>(uri, body, { observe: 'body', params })
          .pipe(catchError((error: HttpErrorResponse) => this.trackError(error)));
    }
  }

  /**
   * tracks the given error and returns it.
   * @param error
   */
  protected trackError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this._insightsService.trackException(error, false);
    return throwError(() => error);
  }

  /**
   * The GET request call iwt
   * @param uri The request URL
   * @param params The HttpParams object
   * @protected
   * @memberof ApiService
   */
  protected postWithFullResponse<T, D>(uri: string, body?: D, params?: HttpParams, headers?: { [id: string]: string }): Observable<HttpResponse<T> | HttpErrorResponse> {
    if (!uri || uri.trim() === '') {
      throw new Error('URI is required');
    }

    return this._httpClient.post<T>(uri, body, { headers, observe: 'response', params });
  }

  /**
   * The DELETE request call
   * @param uri The request URL
   * @param observe The preferred type of response
   * @protected
   * @memberof ApiService
   */
  protected deleteWithFullResponse<T>(uri: string, params?: HttpParams): Observable<HttpResponse<T> | HttpErrorResponse> {
    if (!uri || uri.trim() === '') {
      throw new Error('uri is required');
    }

    return this._httpClient.delete<T>(uri, { observe: 'response', params });
  }
}
