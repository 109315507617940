/**
 * Enumerator for all days of the week
 * @export
 * @enum DayOfWeek
 */
export enum DayOfWeek {
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
  }
