import { Component, Input } from '@angular/core';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Issue } from '@app/models/Issue';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { BasketService } from '@app/api/basket.service';
import { ToastsService } from '@app/shared/services/toasts.service';
import { takeUntil } from 'rxjs';
import { BasketItemView } from '@app/models/basket/basket-item-view';
import { AnalyticsService } from '@app/app-initialisers/analytics-service/analytics.service';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';

@Component({
  selector: 'app-basket-product-item',
  styleUrls: ['./../../shared-basket-styles.scss'],
  templateUrl: './basket-product-item.component.html'
})
export class BasketProductItemComponent extends AndroWebCoreComponent {
  @Input() public item: BasketItemView;
  @Input() public basketIsModifiable: boolean;
  @Input() public showImages: boolean = true;

  constructor(
    private _basketService: BasketService,
    private _toastsService: ToastsService,
    private _analyticsService: AnalyticsService
  ) {
    super();
  }

  /**
  * takes an array of basket issues and returns only unique (by IssueType) issues
  * @issues issue - the basket issues
  */
  public getUniqueItemIssues(issues: Issue[]): Issue[] {
    const response: Issue[] = [];

    if (Array.isArray(issues)) {
      issues?.forEach((y: Issue) => {
        if (response.findIndex((x: Issue) => x.IssueType === y.IssueType) === -1) {
          response.push(y);
        }
      });
    }

    return response;
  }

  /**
  * removes an product from the basket
  * @param basketItemId - the basket item id to be removed
  */
  public removeItem(basketItemId: string): void {
    this._basketService.updateProductQuantity(basketItemId, 0)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: () => {
            this._toastsService.showToast(ToastTypes.error, 'Could not remove item', 'Sorry!');
          },
          next: () => {
            this.trackRemoveItem();
          }
        });
  }

  /**
   * tracks the removal of a product
   */
  private trackRemoveItem(): void {
    const items: GoogleAnalyticsItem[] = [
      {
        'item_id': this.item.productId,
        'item_name': this.item.name,
        'price': this.item.price / this.item.quantity,
        'quantity': this.item.quantity
      }
    ];

    this._analyticsService.trackRemoveFromBasket(this.item.price, items);
  }
}
