import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class IsTenantReadyGuard {
  private retryDelay: number = 250;

  constructor(
    private _configurationService: ConfigurationService,
  ) { }

  public isTenantReady(): Observable<boolean> {
    return this.handleWaitForTenant().pipe(map((value: boolean) => value));
  }

  private handleWaitForTenant(): Observable<boolean> {
    return this._configurationService.isTenantReady.pipe(
        mergeMap((status: boolean) => status ? of(true) : this.retryAfterDelay())
    );
  }

  private retryAfterDelay(): Observable<boolean> {
    return timer(this.retryDelay).pipe(switchMap(() => this.handleWaitForTenant()));
  }
}
