import { Injector } from '@angular/core';

/**
 * Wrapper for the DI injector
 *
 * @export
 * @class AppInjector
 */
export class AppInjector {
  private static injector: Injector;

  /**
   * Sets the injector service
   *
   * @static
   * @param {Injector} injector
   * @memberof AppInjector
   */
  static setInjector(injector: Injector) {
    AppInjector.injector = injector;
  }

  /**
   * Gets the injector service
   *
   * @static
   * @returns {Injector}
   * @memberof AppInjector
   */
  static getInjector(): Injector {
    return AppInjector.injector;
  }
}
