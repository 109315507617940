import { Injectable } from '@angular/core';
import { IAnalyticsProvider } from '@app/models/app-initialisers/IAnalyticsProvider';
import { Subscription } from 'rxjs';
import { ModifierSection } from '@app/models/modifier-section';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticService implements IAnalyticsProvider {
  private _clientTrackingId: string;
  private _enabled = false;

  constructor(public configurationService: ConfigurationService) { }

  /**
   * Initializes Google Analytics.
   * @returns A subscription to the tenant readiness status.
   */
  public googleAnalyticInit(): Subscription {
    return this.configurationService.isTenantReady.subscribe((status: boolean) => {
      if (status) {
        this._clientTrackingId = this.configurationService.getGoogleAnalyticsIdClient();

        try {
          gtag('js', new Date());
          gtag('config', 'UA-165447948-1');
          this._enabled = true;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.info(`%cGA disabled.`, 'color: orange; font-size: 13px');
        }

        return Promise.resolve();
      }
    });
  }

  /**
   * Tracks a page view.
   * @param path - The path of the page.
   * @param title - The title of the page.
   */
  public pageView(path: string, title: string): void {
    if (!this._enabled) return;

    const eventParams = {
      page_path: path,
      page_title: title
    };

    if (this._clientTrackingId) {
      eventParams['send_to'] = this._clientTrackingId;
    }

    gtag('event', 'page_view', eventParams);
  }

  /**
   * Tracks an exception.
   * @param exception The exception object.
   * @param isFatal Indicates if the exception is fatal.
   */
  public trackException(exception: Error, isFatal: boolean): void {
    if (!this._enabled) return;

    const eventParams = {
      description: exception.message,
      fatal: isFatal
    };

    gtag('event', 'exception', eventParams);
  }

  /**
   * Sets up the user ID for tracking.
   * @param userId The user ID.
   */
  public setupUserId(userId: string): void {
    if (!this._enabled) return;

    gtag('set', { 'user_id': userId });
  }

  /**
   * Tracks performance.
   * @param category The category of the performance event.
   * @param path The path of the performance event.
   * @param time The time duration of the performance event.
   * @param label The label for the performance event.
   */
  public trackPerformance(category: string, path: string, time: number, label?: string): void {
    if (!this._enabled) return;

    const eventParams = {
      event_category: category,
      event_label: label,
      name: path,
      value: time
    };

    gtag('event', 'timing_complete', eventParams);
  }

  /**
   * Tracks the view of a product.
   * @param productName The name of the product.
   * @param productId The ID of the product.
   * @param category The category of the product.
   * @param price The price of the product.
   * @param currency The currency of the product price.
   */
  public trackViewProduct(
      productName: string,
      productId: string,
      category: string,
      price: number,
      currency: string,
      variant: string,
      coupon: string
  ): void {
    if (!this._enabled) return;

    const item: GoogleAnalyticsItem = {
      item_brand: this.getHostname(),
      item_category: category,
      item_id: productId,
      item_name: productName,
      price: price,
      item_variant: variant
    };

    if (coupon) {
      item.coupon = coupon;
    }

    const eventParams = {
      currency: currency,
      value: price,
      items: [item]
    };

    gtag('event', 'view_item', eventParams);
  }

  /**
   * Adds the deal to the gtags event for google analytics.
   * @param dealId The ID of the deal.
   * @param dealName The name of the deal.
   * @param category The category of the deal.
   * @param currency The currency of the deal.
   */
  public trackViewDeals(
      dealId: string,
      dealName: string,
      items: [GoogleAnalyticsItem]
  ): void {
    if (!this._enabled) return;

    const eventParams = {
      promotion_name: dealName,
      promotion_id: dealId,
      items
    };

    gtag('event', 'view_promotion', eventParams);
  }

  /**
   * Tracks the click on a product.
   * @param productName The name of the product.
   * @param productId The ID of the product.
   * @param category The category of the product.
   * @param price The price of the product.
   * @param currency The currency of the product price.
   */
  public trackClickProduct(
      productName: string,
      productId: string,
      category: string,
      price: number,
      currency: string
  ): void {
    if (!this._enabled) return;

    const eventParams = {
      currencyCode: currency,
      event_category: 'engagement',
      event_label: 'productClick',
      items: [
        {
          item_brand: this.getHostname(),
          item_category: category,
          item_id: productId,
          item_name: productName,
          price: price
        }
      ]
    };

    if (this._clientTrackingId) {
      eventParams['send_to'] = this._clientTrackingId;
    }

    gtag('event', 'select_item', eventParams);
  }

  public trackViewBasket(value: number, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    gtag('event', 'view_cart', { currency: 'GBP', value, items });
  }

  public trackAddToBasket(value: number, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    gtag('event', 'add_to_cart', { currency: 'GBP', value, items });
  }

  /**
   * Tracks the addition or removal of a product from the basket.
   * @param added Indicates if the product was added (true) or removed (false).
   * @param productName The name of the product.
   * @param productId The ID of the product.
   * @param category The category of the product.
   * @param price The price of the product.
   * @param currency The currency of the product price.
   */
  public trackRemoveFromBasket(value: number, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    gtag('event', 'remove_from_cart', { currency: 'GBP', value, items });
  }

  /**
   * Tracks the checkout process.
   * @param products The list of products in the checkout.
   * @param currency The currency of the products.
   */
  public trackBeginCheckout(value: number, coupon: string, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    gtag('event', 'begin_checkout', { value, coupon, items });
  }

  /**
   * Tracks the checkout process for modifiers.
   * @param modifiers The list of modifiers in the checkout.
   */
  public trackCheckoutModifier(modifiers: ModifierSection[]): void {
    if (!this._enabled) return;

    const modifierArray: any = [];

    modifiers.forEach((p: ModifierSection) => {
      modifierArray.push({
        item_brand: this.getHostname(),
        item_category: 'modifier',
        item_id: p.Id,
        item_name: p.Name
      });
    });

    const eventParams = {
      checkout_option: 'modifiers',
      modifiers: modifierArray
    };

    gtag('event', 'begin_checkout', eventParams);
  }

  /**
   * Tracks a purchase event.
   * @param orderId The ID of the order.
   * @param amount The total amount of the purchase.
   * @param tax The tax amount of the purchase.
   * @param deliveryCharge The delivery charge amount of the purchase.
   * @param voucher The voucher code used in the purchase.
   * @param occasion The occasion of the purchase.
   * @param products The list of products in the purchase.
   */
  public trackPurchaseAndClose(amount: number, orderId: string, voucherCode: string, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    const eventParams = {
      currency: 'GBP',
      value: amount,
      transaction_id: orderId,
      coupon: voucherCode,
      items: items
    };

    gtag('event', 'purchase', eventParams);
    gtag('event', 'close_convert_lead', { currency: 'GBP', value: amount });
  }

  /**
   * Retrieves the hostname of the current window location.
   * @returns The hostname without the "www" prefix.
   */
  private getHostname(): string {
    return window.location.hostname.replace('www.', '');
  }
}
