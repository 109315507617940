import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';

import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { MenuService } from '@app/api/menu.service';
import { QuantityOf } from '@app/models/quantity-of';
import { SEOService } from '@app/shared/services/seo.service';
import { BehaviorSubject, combineLatest, map, Observable, takeUntil } from 'rxjs';
import { BasketItemCreate } from '@app/models/basket/BasketItemCreate';

import { OrderOccasion } from '@app/models/order-occasion';
import { MatDialog } from '@angular/material/dialog';
import { PipesModule } from '@app/modules/pipes/pipes-module';
import { SharedImportsModule } from '@app/shared/shared-imports.module';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { ModifierGroupView } from '@app/models/product/modifier-group-view';
import { ProductInformationView } from '@app/models/product/product-information-view';
import { ProductModifierView } from '@app/models/product/product-modifier-view';
import { ProductOptionGroupView } from '@app/models/product/product-option-group-view';
import { ProductOptionView } from '@app/models/product/product-option-view';
import { ProductView } from '@app/models/product/product-view';
import { MenuHelperService } from '@app/shared/services/menu-helper/menu-helper.service';
import { AllergenSummary } from '@app/models/menu/allergen-summary';
import { Menu } from '@app/models/menu/menu';
import { ModifierOptions } from '@app/models/menu/modifier-options';
import { ProductBase } from '@app/models/menu/product-base';
import { ProductOptions } from '@app/models/menu/product-options';
import { ProductVariant } from '@app/models/menu/product-variant';
import { Variant } from '@app/models/menu/variant';
import { MenuVariantHelperService } from '@app/shared/services/menu-variant-helper/menu-variant-helper.service';
import { AnalyticsService } from '@app/app-initialisers/analytics-service/analytics.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product',
  styleUrls: ['./product.component.scss'],
  templateUrl: './product.component.html',
  standalone: true,
  imports: [SharedMaterialModule, SharedImportsModule, PipesModule]
})
export class ProductComponent extends AndroWebCoreComponent implements OnInit, OnDestroy {
  @Input() public productView: ProductView;

  @Input() private occasion: OrderOccasion;
  @Output() private onClose: EventEmitter<void> = new EventEmitter<void>();

  public isLoading: boolean = true;
  public productPrice$: Observable<number>;
  public quantity$: Observable<number>;
  public optionGroups: ProductOptionGroupView[];
  public modifierGroups: ModifierGroupView[];
  public selectedProductInfo: ProductInformationView;
  public nutritionSummary: string;
  public isVariantAvailable: boolean;
  public variantPrice$: Observable<number>;

  private modifierPrice$: Observable<number>;
  private seoSet: boolean;
  private _variantPrice: BehaviorSubject<number>;
  private _modifiersPrice: BehaviorSubject<number>;
  private _quantitySubject = new BehaviorSubject<number>(1);
  private _menu: Menu;
  private _variant: Variant;
  private _price: number;
  private _itemPrice: number;

  constructor(
    private dialog: MatDialog,
    private seoService: SEOService,
    private menuService: MenuService,
    private _activatedRoute: ActivatedRoute,
    private _analyticsService: AnalyticsService,
    private _menuHelperService: MenuHelperService,
    private _menuVariantHelperService: MenuVariantHelperService
  ) {
    super();
    this._variantPrice = new BehaviorSubject<number>(0);
    this.variantPrice$ = this._variantPrice.asObservable();
    this._modifiersPrice = new BehaviorSubject<number>(0);
    this.modifierPrice$ = this._modifiersPrice.asObservable();
    this._quantitySubject = new BehaviorSubject<number>(1);
    this.quantity$ = this._quantitySubject.asObservable();

    this.productPrice$ = combineLatest([this._variantPrice.asObservable(), this.modifierPrice$, this.quantity$])
        .pipe(map(([variant, modifier, quantity]: number[]) => {
          this._itemPrice = variant + modifier;
          this._price = quantity * this._itemPrice;
          return this._price;
        }));

    this.menuService.menu$
        .pipe(takeUntil(this.destroy$))
        .subscribe((menu) => {
          this._menu = menu;
        });
  }

  ngOnInit() {
    if (this.productView) {
      this.configureVariant(this.productView.variants[0]);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.seoSet) {
      this.seoService.restore();
    }
  }

  public selectOption(option: ProductOptionView): void {
    if (!option.variant) {
      return;
    }

    this.configureVariant(option.variant);
    this.calculateModifiersPrice();
  }

  public addModifier(modifier: ProductModifierView): void {
    if (modifier.quantity === modifier.maxEach) {
      return;
    }

    modifier.quantity++;
    this.calculateModifiersPrice();
  }

  public removeModifier(modifier: ProductModifierView): void {
    if (modifier.quantity === 0) {
      return;
    }

    modifier.quantity--;
    this.calculateModifiersPrice();
  }

  public selectForcedModifier(group: ModifierGroupView, modifier: ProductModifierView): void {
    if (!group.singleSelection || !modifier.available) {
      return;
    }

    group.modifiers.forEach((x: ProductModifierView) => {
      x.quantity = x.id === modifier.id ? 1 : 0;
    });

    group.hasSelection = true;
  }

  /**
   * increases the quantity of the product that should be added to the basket.
   */
  public incrementQuantity(): void {
    this._quantitySubject.next(this._quantitySubject.value + 1);
  }

  /**
   * decreases the quantity of the product that should be added to the basket.
   */
  public decrementQuantity(): void {
    const currentValue = this._quantitySubject.value;
    if (currentValue > 1) {
      this._quantitySubject.next(currentValue - 1);
    }
  }

  public close($event?: KeyboardEvent | MouseEvent): void {
    if ($event) {
      $event.preventDefault();
    }

    this.onClose.emit();
  }

  /**
  * adds the product to the basket and reroutes back to the menu page.
  */
  public async addProductToBasket(): Promise<void> {
    this.isLoading = true;
    await this.productView.save({ item: this.getBasketItemCreate(), itemPrice: this._itemPrice, totalPrice: this._price });
    this.isLoading = false;
  }

  public showAllergenDetails(allergens: AllergenSummary, name: string, description: string, allergensInfoModal: TemplateRef<any>): void {
    this.selectedProductInfo = {
      allergens,
      name,
      description
    };

    const dialogRef = this.dialog.open(allergensInfoModal, {
      id: 'allergensInfoModal',
      maxWidth: '400px',
      panelClass: 'allergens-info-modal'
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.selectedProductInfo = null;
        });
  }

  /**
   * Returns wether the add to cart/deal button is disabled.
   */
  public shouldDisableAddToButton(): boolean {
    return !this.isVariantAvailable || this.modifierGroups.some((x: ModifierGroupView) => x.singleSelection && !x.hasSelection);
  }

  private configureVariant(variant: Variant): void {
    if (!this.productView || this._variant?.Id === variant.Id) {
      return;
    }

    this._variant = variant;
    this.configureOptions(variant);
    this.configureModifiers(variant);
    this.nutritionSummary = variant.Nutrition ? this._menuVariantHelperService.getNutritionSummary([variant], true) : null;
    this.isVariantAvailable = this._menuVariantHelperService.areVariantsAvailableForOccasion([variant], this.occasion, new Date());
    const price = this._menuHelperService.getPriceForOccasion(variant.Prices, this.occasion);
    this._variantPrice.next(price);

    const options = this.optionGroups.flatMap((x: ProductOptionGroupView) => x.options);

    const optionNames = variant.OptionsPath
        .map((x: string) => options.find((y: ProductOptionView) => y.id === x).name)
        .filter((x: string) => x)
        .join(' - ');

    this._analyticsService.trackViewProduct(
        this.productView.name,
        variant.Id,
        this._activatedRoute.snapshot.paramMap.get('displayGroup'),
        price,
        'GBP',
        optionNames,
        this.productView.dealName
    );
    this.isLoading = false;
  }

  private configureModifiers(variant: Variant): void {
    this.modifierGroups = [];

    if (variant.Modifiers) {
      for (const id of Array.from(new Set(variant.Modifiers.Optional.concat(variant.Modifiers.Default)))) {
        const modifier: ProductVariant = this.menuService.productVariantsById[id];

        if (!modifier) {
          continue;
        }

        let modifierOptions: ModifierOptions = null;
        const groupId: string = modifier.Product.ModifierGroup;

        if (groupId) {
          modifierOptions = this._menu.ModifierOptions.find((x: ModifierOptions) => x.Id === groupId);
        }

        const modifierView: ProductModifierView = {
          allergens: this._menuHelperService.getAllergenSummary(modifier.Product.Allergens),
          name: modifier.Product.Name,
          price: this._menuHelperService.getPriceForOccasion(modifier.Variant.Prices, this.occasion),
          maxEach: variant.Modifiers.MaxEach,
          available: !modifier.Variant.OutOfStock,
          id: modifier.Variant.Id,
          quantity: variant.Modifiers.Default.some((x: string) => x === modifier.Variant.Id) ? 1 : 0
        };

        if (modifier.Product.Description) {
          modifierView.description = modifier.Product.Description;
        }

        if (modifier.Product.ImageBase) {
          modifierView.imageSource = `${modifier.Product.ImageBase}/landscape-small.jpg`;
        }

        if (modifierOptions?.SingleSelection) {
          modifierView.singleSelection = modifierOptions?.SingleSelection;
        }

        const groupView: ModifierGroupView = this.modifierGroups.find((x: ModifierGroupView) => x.id == modifierOptions?.Id);

        if (groupView) {
          groupView.modifiers.push(modifierView);
        } else {
          this.modifierGroups.push({
            id: modifierOptions?.Id,
            name: modifierOptions?.Name ?? `Available ${this.tenant.CustomerModifierText ?? 'Modifiers'}`,
            modifiers: [modifierView],
            singleSelection: modifierOptions?.SingleSelection
          });
        }
      }

      this.modifierGroups
          .filter((x: ModifierGroupView) => x.singleSelection)
          .forEach((x: ModifierGroupView) => {
            x.hasSelection = x.modifiers.some((z: ProductModifierView) => z.quantity > 0);
          });
    }
  }

  private configureOptions(variant: Variant): void {
    this.optionGroups = [];
    let variantPathLength = 0;

    this._menuHelperService.getApplicableProductOptions(this._menu.ProductOptions, this.productView.variants)
        .forEach((x: ProductOptions) => {
          const group: ProductOptionGroupView = {
            id: x.Id,
            name: x.Name,
            description: x.Description,
            options: x.Options.map((option: ProductBase) => this.mapToProductOptionView(option, variant, variantPathLength))
          };

          this.optionGroups.push(group);
          variantPathLength++;
        });
  }

  private mapToProductOptionView(option: ProductBase, variant: Variant, variantPathLength: number): ProductOptionView {
    let variants = [];

    if (variantPathLength === 0) {
      variants = this.productView.variants.filter((z: Variant) => z.OptionsPath.includes(option.Id));
    } else {
      const currentVariantPath: string[] = [...variant.OptionsPath];

      if (currentVariantPath.length > variantPathLength) {
        currentVariantPath[variantPathLength] = option.Id;
      } else {
        currentVariantPath.push(option.Id);
      }

      variants = this.productView.variants.filter((z: Variant) => this.sequenceEqual(z.OptionsPath, currentVariantPath));
    }

    return {
      id: option.Id,
      name: option.Name,
      description: option.Description,
      allergens: this._menuHelperService.getAllergenSummary(option.Allergens),
      nutritionSummary: this._menuVariantHelperService.getNutritionSummary(variants, true),
      selected: variants.some((z: Variant) => z.Id === variant.Id),
      variant: variants[0],
      price: this._menuVariantHelperService.getPriceSummaryForVariants(variants, this.occasion, false, this.productView.isInDeal)
    } satisfies ProductOptionView;
  }

  private getBasketItemCreate(): BasketItemCreate {
    const modifiers: QuantityOf[] = this.modifierGroups
        .flatMap((group: ModifierGroupView) => group.modifiers)
        .filter((modifier: ProductModifierView) => modifier.quantity > 0)
        .map((modifier: ProductModifierView) => ({ Item: modifier.id, Quantity: modifier.quantity }));

    return {
      Modifiers: modifiers,
      Product: {
        Item: this._variant.Id,
        Quantity: this._quantitySubject.value
      }
    };
  }

  /**
   * Calculates the total price of selected modifiers based on their quantity.
   * Modifiers are applied according to the max free modifiers rule, which means
   * that a certain number of modifiers can be added for free. Any additional modifiers
   * are charged according to their price.
   */
  private calculateModifiersPrice(): void {
    const prices: number[] = [];

    const modifiers: ProductModifierView[] = this.modifierGroups
        .flatMap((x: ModifierGroupView) => x.modifiers);

    for (const modifier of modifiers) {
      for (let i = 0; i < modifier.quantity; i++) {
        prices.push(modifier.price);
      }
    }

    let selectedTotal: number = 0;

    // If there are modifiers and more than the allowed free ones, calculate the total for the extra ones
    if (this._variant.Modifiers && prices.length >= this._variant.Modifiers.MaxFree) {
      selectedTotal = prices
          .sort((a: number, b: number) => a - b) // Sort prices in ascending order
          .slice(this._variant.Modifiers.MaxFree) // Remove the free modifiers
          .reduce((total: number, price: number) => total + price, 0); // Sum up the remaining prices
    }

    this._modifiersPrice.next(selectedTotal);
  }
}
