// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voucher-input {
  border: 1px solid #ccc;
  padding: 0 5px;
  height: 37px;
  margin-right: 5px;
  flex: 1;
  border-radius: 6px;
  max-width: calc(100% - 107px);
  text-overflow: ellipsis;
  font-size: 13px;
}

.voucher-input.disabled {
  border-color: red; 
  border-width: 2px;
}

.basket-charge-input-group {
    display: flex;
    align-items: center;
    padding: 5px 0;
    width: 100%;
}

.spinner-container {
    align-items: center;
    cursor: default;
    display: flex;
    justify-content: center;
    position: absolute;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
    z-index: 9;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
