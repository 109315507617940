import { IEventTelemetry } from '@microsoft/applicationinsights-web';

export class EventTelemetry implements IEventTelemetry {
  name = 'Basket';
  properties?: { [key: string]: any; };

  public constructor(category: string, actionName: string, label: string, value: number) {
    this.name = category;
    this.properties = [
      { 'Category': category },
      { 'Action': actionName },
      { 'Label': label },
      { 'Value': value },
    ];
  }
}
