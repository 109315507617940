/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationInsights, IEventTelemetry, IPageViewPerformanceTelemetry, IPageViewTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { IAnalyticsProvider } from '@app/models/app-initialisers/IAnalyticsProvider';
import { Subscription } from 'rxjs';
import { ModifierSection } from '@app/models/modifier-section';
import { EventTelemetry } from '@app/models/app-initialisers/EventTelemetry';
import { ExceptionTelemetry } from '@app/models/app-initialisers/ExceptionTelemetry';
import { MetricTelemetry } from '@app/models/app-initialisers/MetricTelemetry';
import { PageViewTelemetry } from '@app/models/app-initialisers/PageViewTelemetry';
import { TraceTelemetry } from '@app/models/app-initialisers/TraceTelemetry';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';

@Injectable({
  providedIn: 'root'
})
export class InsightsService implements IAnalyticsProvider, OnDestroy {
  private appInsights: ApplicationInsights;
  private subscription: Subscription;

  constructor(public configurationService: ConfigurationService) { }

  insightsInit() {
    this.subscription = this.configurationService.isTenantReady.subscribe((status: boolean) => {
      if (status) {
        this.appInsights = new ApplicationInsights({
          config: {
            accountId: this.configurationService.getTenantId(),
            enableAutoRouteTracking: true,
            instrumentationKey: this.configurationService.getInstrumentationKey(),
            loggingLevelTelemetry: 2
          }
        });

        this.appInsights.loadAppInsights();

        const cloudRoleName = this.configurationService.getTelemetryRoleName();
        if (cloudRoleName) {
          this.appInsights.addTelemetryInitializer((envelope) => {
            envelope.tags['ai.cloud.role'] = cloudRoleName;
          });
        }

        return Promise.resolve();
      }
    });

    return this.subscription;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public trackAction(category: string, actionName: string, label: string, value: number): void {
    this.appInsights?.trackEvent(new EventTelemetry(category, actionName, label, value));
  }

  public pageView(path: string, title: string): void {
    this.appInsights?.trackPageView(new PageViewTelemetry(path, title));
  }

  public trackException(exception: Error, isFatal: boolean): void {
    this.appInsights?.trackException(new ExceptionTelemetry(exception));
  }

  public trackTrace(message: string, severity: SeverityLevel, properties?: ICustomProperties): void {
    const _properties: ICustomProperties = properties ? properties : {};
    _properties['href'] = window.location.href;

    this.appInsights?.trackTrace(new TraceTelemetry(message, severity), _properties);
  }

  public setupUserId(userId: string) {
    this.appInsights?.setAuthenticatedUserContext(userId);
  }

  public trackPerformance(category: string, path: string, time: number, label?: string): void {
    this.appInsights?.trackMetric(new MetricTelemetry(path, time));
  }

  public trackViewProduct(productName: string, productId: string, category: string, price: number, currency: string): void {
    return;
  }

  trackViewDeals(dealId: string, dealName: string): void {
    return;
  }

  public trackViewBasket(value: number, items: GoogleAnalyticsItem[]): void {
    return;
  }

  public trackClickProduct(productName: string, productId: string, category: string, price: number, currency: string): void {
    return;
  }

  public trackAddToBasket(value: number, items: GoogleAnalyticsItem[]): void {
    return;
  }

  public trackRemoveFromBasket(value: number, items: GoogleAnalyticsItem[]): void {
    return;
  }

  public trackBeginCheckout(value: number, coupon: string, items: GoogleAnalyticsItem[]): void {
    return;
  }

  public trackCheckoutOption(step: number, option: any): void {
    return;
  }

  public trackCheckoutModifier(modifiers: ModifierSection[]): void {
    return;
  }

  public trackPurchaseAndClose(amount: number, orderId: string, voucherCode: string, items: GoogleAnalyticsItem[]): void {
    return;
  }

  public trackEvent(event: IEventTelemetry) {
    this.appInsights?.trackEvent(event);
  }

  public trackPageView(data: IPageViewTelemetry) {
    this.appInsights?.trackPageView(data);
  }

  public trackPageViewPerformance(pageData: IPageViewPerformanceTelemetry) {
    this.appInsights?.trackPageViewPerformance(pageData);
  }

  public setUserId(userId: string) {
    this.appInsights?.setAuthenticatedUserContext(userId);
  }

  public clearUserId() {
    this.appInsights?.clearAuthenticatedUserContext();
  }
}
