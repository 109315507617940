// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.nav-details-basket {
  display: flex;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
}
.nav-details-basket .mat-icon {
  margin-right: 10px;
  margin-top: -3px;
  font-size: 22px;
  height: 22px;
  width: 22px;
}

.cart-label {
  padding-right: 15px;
}
.cart-label .mat-icon {
  font-size: 21px;
  height: 21px;
  width: 21px;
}

.cart-total-label {
  color: #ff1e1e;
  cursor: pointer;
  display: inline-block;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  font-size: 34px;
  min-width: 39px;
  text-align: center;
}

.desktop-nav {
  display: flex;
  flex-direction: row;
  position: relative;
}

.dropdown-Item {
  align-items: center;
  display: flex;
}
.dropdown-Item .mat-icon {
  font-size: 21px;
  width: 21px;
  margin-left: 6px;
}
.dropdown-Item-p {
  padding-left: 7px;
  color: #000;
}
.dropdown-Item:hover {
  box-shadow: 0 3px 1px -2px #E3000E;
}

.logo img {
  margin: 2px 0;
  max-height: 86px;
  padding-left: 20px;
}

.dropdown-menu.show ul {
  list-style: none;
  padding: 0 10px;
  margin: 0;
}

.dropdown-menu.show ul li {
  padding: 10px 0;
  cursor: pointer;
}

.menu-left .dropdown-menu.show {
  left: -30px !important;
}

.mobile-menu-list.icon {
  align-items: center;
  display: flex;
  color: #E3000E;
  font-size: 20px;
  height: 30px;
  justify-content: center;
  opacity: 0.85;
  width: 30px;
  padding: 0px;
}

.mobile-nav {
  display: none;
}

.modal-body.nav {
  padding: 16px;
}
.modal-body.nav p {
  margin: 0;
}
.modal-button-light.nav {
  background-color: #fff;
  border: thin solid #dbdfe6;
  color: #E3000E;
  display: flex;
  justify-content: center;
  width: 35%;
}
.modal-button-light.nav:hover {
  background-color: #E3000E;
  box-shadow: 0 0 8px 1px rgba(53, 53, 53, 0.25);
  color: #fff;
}
.modal-header.nav, .modal-footer.nav {
  padding: 8px;
}
.modal-title.nav {
  color: #E3000E;
  font-weight: 700;
}

.modal-header.nav {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.modal-header.nav .mat-icon {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  height: 28px;
  width: 28px;
  cursor: pointer;
  opacity: 0.8;
}
.modal-header.nav .mat-icon:hover {
  color: #E3000E;
}

.modal-nav.close {
  margin: 3px 0 0;
  opacity: 0.7;
  padding: 0;
}

.nav-bar {
  background-color: var(--nav-bar-background-colour);
  box-shadow: 0 0 8px 1px rgba(53, 53, 53, 0.25);
  height: 90px;
}

.nav-cards-tool-tip-text:hover {
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.5);
}

.nav-cards-tool-tip-text .mat-icon {
  font-size: 20px;
  height: 20px;
}

.sitename {
  display: flex;
  flex-direction: row;
  padding-right: 35px;
  position: absolute;
  right: 0;
}
.sitename-current {
  margin-left: 4px;
}
.sitename-current:hover {
  color: #E3000E;
}

.sitename .nav-cards-tool-tip {
  background-color: #3f4858;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.175);
  border: thin solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  cursor: pointer;
  display: none;
  height: 45px;
  margin: 25px 0 0 79px;
  position: absolute;
  width: 200px;
  z-index: 1;
}
.sitename .nav-cards-tool-tip-text {
  align-items: center;
  border-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 17px;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.sitename:hover .nav-cards-tool-tip {
  display: block;
}

.sitename:hover .sitename-current {
  color: #E3000E;
}

.text-info-label p {
  margin: 0;
}

.nav-bar-corporate-link-a:hover {
  color: #E3000E;
}

.flex-center {
  display: flex;
  align-items: center;
}

.desktop-nav {
  height: 90px;
}
.desktop-nav-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.desktop-nav-details-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  margin-top: 10px;
}
.desktop-nav-details-corporate {
  display: flex;
  align-items: center;
  padding: 0 35px;
}
.desktop-nav-details-sitename {
  display: flex;
  justify-content: flex-end;
  padding: 0 35px;
  height: 25px;
}
.desktop-nav-details-usermenu {
  display: flex;
  align-items: center;
}
.desktop-nav-logo-image {
  margin: 2px 0;
  max-height: 86px;
  padding-left: 20px;
  cursor: pointer;
}

.nav-bar.on-home {
  height: 150px;
}
.nav-bar.on-home .desktop-nav {
  display: flex;
  align-items: center;
  height: 150px;
  justify-content: center;
}
.nav-bar.on-home .desktop-nav-details {
  position: absolute;
  right: 10px;
  top: 10px;
  height: calc(100% - 20px);
}
.nav-bar.on-home .desktop-nav-logo-image {
  max-height: 140px;
}

.desktop-nav-logo-wrapper {
  display: flex;
  align-items: center;
}

.user-dropdown-button {
  background-color: #fff;
}

.user-dropdown-button .mat-icon {
  margin-top: -4px;
}

.back-to-menu-button {
  position: absolute !important;
  left: 5px;
  background-color: transparent;
}

.slide-out-menu-item {
  padding: 16px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.slide-out-menu-item .text-info-label {
  color: #000;
}

@media screen and (max-width: 1023px) {
  .cart-label {
    padding-right: 10px;
  }
  .nav-details-basket {
    padding: 0;
    position: absolute;
    right: 0;
  }
  .desktop-nav {
    display: none;
    visibility: hidden;
  }
  .mobile-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .logo {
    display: flex;
    flex: 1;
    justify-content: center;
    max-width: calc(100% - 70px);
    overflow: hidden;
  }
  .logo img {
    max-height: 80px;
    padding: 0;
    cursor: pointer;
  }
  .mobile-menu-list-item.head {
    background-color: #3f4858;
    color: #fff;
    display: block;
  }
  .menu-icon {
    align-items: center;
    display: flex;
    font-size: 45px;
    flex: 1;
    height: 90px;
    justify-content: center;
    max-width: 68px;
    padding-right: 10px;
    left: 0;
    position: absolute;
  }
  .menu-icon-holder {
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 68px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    -o-transform: rotate(0deg);
    -o-transition: 0.5s ease-in-out;
    -moz-transform: rotate(0deg);
    -moz-transition: 0.5s ease-in-out;
    -webkit-transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
  }
  .menu-icon-holder span {
    background: #000;
    border-radius: 9px;
    display: block;
    height: 4px;
    left: 10px;
    opacity: 1;
    position: absolute;
    width: 65%;
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
    -webkit-transform: rotate(0deg);
  }
  .menu-icon-holder span:nth-child(1) {
    top: 0;
  }
  .menu-icon-holder span:nth-child(2) {
    top: 15px;
  }
  .menu-icon-holder span:nth-child(3) {
    top: 30px;
  }
  .menu-icon-holder.open span {
    background: #E3000E;
  }
  .menu-icon-holder.open span:nth-child(1) {
    top: 18px;
    transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .menu-icon-holder.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  .menu-icon-holder.open span:nth-child(3) {
    top: 18px;
    transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .modal-button-light.nav {
    width: 48%;
  }
  .modal-footer.nav {
    justify-content: space-evenly;
  }
  .sitename {
    display: flex;
    flex-direction: column;
    padding: 0 2%;
    position: relative;
  }
  .slider-content-closed {
    display: block;
    height: 100%;
    left: -100%;
    position: fixed;
    top: 90px;
    width: 0;
    transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
  }
  .slider-content-open {
    background-color: #fff;
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 90px;
    width: 50%;
    z-index: 999;
    box-shadow: inset 0px 1px 2px -1px rgba(0, 0, 0, 0.5);
  }
}
@media screen and (max-width: 479px) {
  .slider-content-open {
    width: 100%;
  }
  .button-action-1, .button-action-2 {
    padding: 8px 15px;
  }
  .desktop-nav {
    height: 45px;
  }
  .desktop-nav-details-sitename {
    display: none;
  }
  .desktop-nav-logo-image {
    max-height: 40px;
    padding-left: 10px;
  }
  .sign_btn a {
    padding: 7px 15px;
    border-radius: 9px;
  }
  .menu-icon {
    max-width: 68px;
    height: 45px;
  }
  .menu-icon-holder span {
    width: 60%;
  }
  .menu-icon-holder span:nth-child(1) {
    top: 8px;
  }
  .menu-icon-holder span:nth-child(2) {
    top: 18px;
  }
  .menu-icon-holder span:nth-child(3) {
    top: 28px;
  }
  .logo {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .logo img {
    max-height: 40px;
    width: unset;
  }
  .nav-bar {
    height: 45px;
  }
  .slider-content-closed {
    top: 45px;
  }
  .slider-content-open {
    top: 45px;
  }
  .sitename.text-info-label {
    display: none;
  }
}
.user-dropdown {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
}

.mobile-nav .user-dropdown {
  position: absolute;
  right: 0;
  overflow: hidden;
}

.mobile-nav {
  position: relative;
  height: 100%;
}

.user-menu-dropdown-list a {
  display: flex;
  align-items: center;
  padding: 0;
}
.user-menu-dropdown-list a p {
  margin-bottom: -1px;
}
.user-menu-dropdown-list a .dropdown-Item-icon {
  align-items: center;
}

.user-menu-dropdown-list-inner-container {
  justify-content: flex-start;
  padding: 0 16px;
  align-items: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
