import { BasketTelemetry } from '@app/models/app-initialisers/BasketTelemetry';

export class BasketEventTelemetry extends BasketTelemetry {
  constructor(
    readonly basketId: string,
    readonly action: string,
    readonly productId: string,
    readonly productName: string,
  ) {
    super(basketId, action);
    this.properties['Product Id'] = productId;
    this.properties['Product Name'] = productName;
  }
}
