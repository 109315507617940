// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-error-outline {
  border-color: red;
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
}

.input-group-text {
  border: none;
  color: #929DB1;
  display: flex;
  justify-content: center;
  width: 40px;
  padding: 12px 0;
}

.androweb-form-group.with-prefix input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -3px !important;
}

.androweb-form-group input::-webkit-outer-spin-button,
.androweb-form-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.androweb-form-group input {
  -moz-appearance: textfield;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
